import { useCallback, useRef, useState } from "react"
import classNames from "classnames"
import { CellInput } from "components/ImportComponents/components/validatingStep/CellInput"
import { AccessTokenCell, FieldType } from "./types"
import throttle from "@jcoreio/async-throttle"
import { IdentityKey } from "admin-client-server/src/utils/identitiesUtils"

type Props = {
	column: any
	cell: AccessTokenCell
	rowId: string
	isNewRow: boolean
	columnId: FieldType
	cellIndex: number
	editCell: (
		rowId: string,
		isNewRow: boolean,
		columnKey: FieldType,
		value: string,
		error?: string
	) => void
	setCellError: (isNewRow: boolean, rowId: string, columnKey: FieldType, error: string) => void
	getAutocompleteOptions: (key: IdentityKey, value: string) => Promise<any>
	onAutocompleteOptionSelected: (rowId: string, option: any) => void
	disabled: boolean
}

export function CellWithAutocomplete({
	column,
	cell,
	rowId,
	isNewRow,
	columnId,
	cellIndex,
	editCell,
	setCellError,
	getAutocompleteOptions,
	onAutocompleteOptionSelected,
	disabled,
}: Props) {
	const [autocompleteOptions, setAutocompleteOptions] = useState<any[]>([])

	const updateWithAutocompleteDebounced = useRef(
		throttle(async (val: string, columnId: any) => {
			const { options, hasError } = await getAutocompleteOptions(columnId, val)

			setAutocompleteOptions(options)

			setCellError(isNewRow, rowId, columnId, hasError ? "errors:mustBeUnique" : "")
		}, 500)
	)

	const onValueChange = useCallback(
		async (val: string) => {
			updateWithAutocompleteDebounced.current(val, columnId)
			editCell(rowId, isNewRow, columnId, val)
		},
		[columnId, editCell, isNewRow, rowId]
	)

	const handleAutocompleteOptionClick = (rowId: string, option: any) => {
		onAutocompleteOptionSelected(rowId, option)
		setAutocompleteOptions([])
	}

	return (
		<div className={classNames("relative m-1")}>
			<CellInput
				cell={cell}
				sectionKey={rowId}
				columnKey={columnId}
				cellIndex={cellIndex}
				onValueChange={onValueChange}
				className=""
				disabled={disabled}
			/>
			{!!autocompleteOptions.length && (
				<div className="absolute z-10 bg-white w-full border border-black border-2 border-t-0 max-h-[200px] overflow-y-scroll">
					{autocompleteOptions!.map(option => (
						<div
							key={option[columnId]}
							className="border-b py-1 px-2 font-dmSans text-sm hover:bg-grey1 cursor-pointer"
							onClick={() => handleAutocompleteOptionClick(rowId, option)}
						>
							{option[columnId]}
						</div>
					))}
				</div>
			)}
		</div>
	)
}
