import { Page } from "components/Page"
import {
	TimeFilterCustomDate,
	getCustomTimeFrame,
	getTimeFrame,
} from "components/pageCards/filterSort/filterTimeFrame"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useCommonEntitiesStore } from "States/commonEntities"
import { useTerminalsState } from "States/Terminals"
import { useDataWareHouse } from "Utils/api/datawarehouse/request"
import { EmptyView } from "components/EmptyView/EmptyView"
import { Header, TableWithSelect } from "components/TableWithSelect"
import { sum, uniq } from "lodash"
import { exportCsv } from "./exportCsv"
import { exportExcel, getSortedWasteTypes } from "./exportExcel"
import { SortingState } from "@tanstack/react-table"
import { StickyFooterComponent } from "./stickyFooterComponent"
import { getWasteTypeName } from "Utils/commonExportFunctions"
import { formatWeight } from "Utils/formatFunctions"
import { weightRender } from "components/TableWithSelect/utils/cellRenderFunctions"
import { sortAlphabeticallyByProperty } from "Utils/sorting"
import { ActiveOptions } from "components/pageCards/filterSort/FilterSortContext"
import {
	FilterTimeFrameValue,
	createFractionFilter,
	createTimeFilters,
} from "components/pageCards/filterSort/filterCreators"
import { Card } from "components/pageCards/card"
import { FilterSort, FilterSortMenuType } from "components/pageCards/filterSort/types"
import { OPTION_ALL } from "components/pageCards/filterSort/constants"
import { useWasteTypeConfig } from "pages/configuration/useWasteTypeConfig"

export type TenantType = {
	id: string
	name: string
	total: number
	[k: string]: string | number
}

type ReportingFilters = "timeframe" | "fractionId"
const defaultPeriod = FilterTimeFrameValue.LAST_30_DAYS

export const Reporting: React.FC = () => {
	const [
		{ timeframe: [selectedTimeframe] = [], fractionId: [selectedFractionId] = [] },
		setActiveOptions,
	] = useState<ActiveOptions<ReportingFilters>>({} as any)

	const { currentTerminal } = useTerminalsState()
	const { wasteTypes: sanityWasteTypes } = useCommonEntitiesStore()
	const { wasteTypeConfig } = useWasteTypeConfig()
	const [selectedIds, setSelectedIds] = useState<string[]>([])
	const [sorting, setSorting] = useState<SortingState>([{ id: "name", desc: false }])

	const filterTimeframe = useMemo(() => {
		if (selectedTimeframe?.option === "interval.custom") {
			return getCustomTimeFrame(selectedTimeframe.value as TimeFilterCustomDate)
		} else {
			return getTimeFrame((selectedTimeframe?.value as FilterTimeFrameValue) ?? defaultPeriod)
		}
	}, [selectedTimeframe])

	const {
		data,
		isError: isErrorTenants,
		isLoading: isLoadingTenants,
	} = useDataWareHouse({
		endpoint: "terminal/customers",
		pathParams: { id: currentTerminal.id },
		filters: filterTimeframe,
	})

	useEffect(() => {
		setSelectedIds([])
	}, [data])

	const allWasteTypes = useMemo(() => {
		const wasteTypes: string[] = []

		data?.customers.forEach(tenant => {
			const individualWasteTypes = tenant.wasteTypes.map(wt => wt.code)

			wasteTypes.push(...individualWasteTypes)
		})

		return uniq(wasteTypes)
	}, [data])

	const filteredWasteTypes = useMemo(
		() =>
			selectedFractionId?.value === OPTION_ALL || !selectedFractionId
				? allWasteTypes
				: allWasteTypes.filter(wt => wt === selectedFractionId?.value),
		[allWasteTypes, selectedFractionId]
	)

	const tenantsReportData = useMemo(() => {
		if (!data || !sanityWasteTypes) {
			return []
		}

		const tenants = data.customers
			.filter(t => !!t.customerId)
			.map(tenant => {
				const name = tenant.customerName

				// Individual waste types data
				const individualWasteTypes = tenant.wasteTypes.map(wt => ({
					amount: wt.weight.quantity,
					wasteCode: wt.code,
				}))

				const tenantData: TenantType = {
					id: tenant.customerId,
					name,
					total: formatWeight(
						sum(
							individualWasteTypes
								.filter(wt => filteredWasteTypes.includes(wt.wasteCode))
								.map(el => formatWeight(el.amount) || 0)
						)
					),
				}

				filteredWasteTypes.forEach(code => {
					const wt = individualWasteTypes.find(el => el.wasteCode === code)

					if (wt) {
						tenantData[wt.wasteCode] = formatWeight(wt.amount)
					} else {
						tenantData[code] = ""
					}
				})

				return tenantData
			})

		return tenants
	}, [data, sanityWasteTypes, filteredWasteTypes])

	const filters: FilterSort[] = useMemo(
		() => [
			createTimeFilters(
				{
					id: "timeframe",
					menuType: FilterSortMenuType.TimeFrame,
					defaultValue: defaultPeriod,
				},
				[
					FilterTimeFrameValue.LAST_30_DAYS,
					FilterTimeFrameValue.LAST_MONTH,
					FilterTimeFrameValue.YEAR_TO_DATE,
					FilterTimeFrameValue.LAST_YEAR,
					FilterTimeFrameValue.LAST_6_FULL_MONTHS,
					FilterTimeFrameValue.LAST_FULL_YEAR,
					FilterTimeFrameValue.SINCE_BEGINNING,
				]
			),
			createFractionFilter(
				allWasteTypes.map(wt => ({ code: wt })),
				sanityWasteTypes,
				wasteTypeConfig
			),
		],
		[allWasteTypes, sanityWasteTypes, wasteTypeConfig]
	)

	const showError = useMemo(
		() => !isLoadingTenants && isErrorTenants,
		[isLoadingTenants, isErrorTenants]
	)
	const showEmpty = useMemo(
		() => !isLoadingTenants && !isErrorTenants && !tenantsReportData.length,
		[isLoadingTenants, isErrorTenants, tenantsReportData]
	)

	const showTable = useMemo(
		() => isLoadingTenants || (tenantsReportData.length! > 0 && !showError),
		[isLoadingTenants, tenantsReportData, showError]
	)

	const tableHeaders: Header[] = useMemo(
		() => [
			{ key: "name", title: "entities:tenant", defaultSize: 170, stickyLeft: true },
			{ key: "id", title: "genericLabels:tenantId" },
			...filteredWasteTypes
				.map(wt => ({
					key: wt,
					title: `${
						wasteTypeConfig.find(el => el.wasteTypeCode === wt)?.name ||
						getWasteTypeName(sanityWasteTypes!, wt)
					} (${wt})`,
					defaultSize: 300,
					rightAlign: true,
					renderFunction: weightRender,
				}))
				.sort((a, b) => sortAlphabeticallyByProperty(a, b, "title")),
			{
				key: "total",
				title: "genericLabels:totalPerTenant",
				rightAlign: true,
				defaultSize: 270,
				stickyRight: true,
				renderFunction: weightRender,
			},
		],
		[filteredWasteTypes, sanityWasteTypes, wasteTypeConfig]
	)

	const onRowClick = useCallback(
		(el: TenantType) => {
			if (selectedIds.includes(el.id)) {
				setSelectedIds(selectedIds.filter(id => id !== el.id))
			} else {
				setSelectedIds([...selectedIds, el.id])
			}
		},
		[selectedIds]
	)

	const getStickyFooterComponent = useCallback(
		(ref: HTMLTableElement) => (
			<StickyFooterComponent
				tableRef={ref}
				headers={tableHeaders}
				filteredWasteTypes={filteredWasteTypes}
				tenants={tenantsReportData}
				currentTerminalName={currentTerminal.name}
			/>
		),
		[currentTerminal, tableHeaders, filteredWasteTypes, tenantsReportData]
	)

	const getSortedData = useCallback(() => {
		const filter = sorting[0]

		const selectedData = selectedIds.length
			? (tenantsReportData || []).filter((el: any) => selectedIds.includes(el.id))
			: tenantsReportData || []

		const sortedData = selectedData.sort((a, b) => {
			const compareResult = a[filter.id].toString().localeCompare(b[filter.id].toString())

			return filter.desc ? -compareResult : compareResult
		})

		return sortedData
	}, [sorting, tenantsReportData, selectedIds])

	const exportExcelFunction = useCallback(() => {
		const sortedData = getSortedData()
		exportExcel({
			tenants: sortedData,
			wasteTypes: getSortedWasteTypes(filteredWasteTypes, wasteTypeConfig, sanityWasteTypes || []),
			nrSelected: selectedIds.length,
			nrTotal: tenantsReportData.length,
			timeframe: filterTimeframe,
			sanityWasteTypes: sanityWasteTypes || [],
			wasteTypeConfig,
		})
	}, [
		filteredWasteTypes,
		selectedIds,
		filterTimeframe,
		sanityWasteTypes,
		getSortedData,
		tenantsReportData,
		wasteTypeConfig,
	])

	const exportCsvFunction = useCallback(() => {
		const sortedData = getSortedData().map(el => el.id)
		exportCsv({
			tenants: sortedData.map(id => data?.customers.find(t => t.customerId === id)!),
			wasteTypes: getSortedWasteTypes(filteredWasteTypes, wasteTypeConfig, sanityWasteTypes || []),
			timeframe: filterTimeframe,
			sanityWasteTypes: sanityWasteTypes || [],
			wasteTypeConfig,
		})
	}, [data, filterTimeframe, filteredWasteTypes, sanityWasteTypes, getSortedData, wasteTypeConfig])

	return (
		<Page title="sidebarLabels:reporting" fullHeight>
			<Card
				overflow="auto"
				filters={filters}
				onFilterOptionChange={setActiveOptions}
				hasYContentPadding={false}
				hasXContentPadding={false}
				exportButton={{
					nrSelected: selectedIds.length,
					areAllSelected: tenantsReportData.length === selectedIds.length,
					exportExcelFunction,
					exportCsvFunction,
				}}
			>
				{showError && <EmptyView type="unableToFetchEvents" />}
				{showEmpty && <EmptyView type="noEvents" />}
				{showTable && (
					<>
						<TableWithSelect
							selectedIds={selectedIds}
							setSelectedIds={setSelectedIds}
							headers={tableHeaders}
							defaultSortingHeader={{ id: "name", desc: false }}
							updateSorting={setSorting}
							labelSingle="entities:event"
							labelMultiple="sidebarLabels:events"
							showFooter={false}
							stickyCheckbox
							{...{
								data: tenantsReportData,
								isLoading: isLoadingTenants,
								onRowClick,
								getStickyFooterComponent,
							}}
						/>
					</>
				)}
			</Card>
		</Page>
	)
}

export default Reporting
