import { CustomersResponse } from "Utils/api/datawarehouse/responseTypes"
import { Terminal } from "Utils/api/datawarehouse/responseTypes"
import { FetchedWasteTypes } from "Utils/api/sanity/types"
import { CollapsableTableDataInterface, WasteTypeDetails } from "./customerDetails.interface"
import { removeMoreThanTwoDecimals } from "Utils/formatFunctions"
import { sortAlphabeticallyByProperty } from "Utils/sorting"
import { WasteTypeConfig } from "pages/configuration/useWasteTypeConfig"

type PeriodSummary = {
	recyclingRate: number
	wasteTypes: number
	throws: number
	residualWaste: number
	totalWeight: number
}

export const calcAverages = (
	firstPeriod: CustomersResponse,
	currentPeriod: CustomersResponse,
	realEstateData?: Terminal
) => {
	const initSummary: PeriodSummary = {
		recyclingRate: 0,
		wasteTypes: 0,
		throws: 0,
		residualWaste: 0,
		totalWeight: 0,
	}
	const sumsPrevPeriod: PeriodSummary = firstPeriod.customers.reduce((acc: PeriodSummary, el) => {
		return {
			recyclingRate: acc.recyclingRate + el.sortingDegree,
			wasteTypes: acc.wasteTypes + el.wasteTypes.length,
			throws: acc.throws + el.totalOccurrences,
			residualWaste: acc.residualWaste + el.totalResidualWasteWeight.quantity,
			totalWeight: acc.totalWeight + el.totalWasteWeight.quantity,
		}
	}, initSummary)

	const sums: PeriodSummary = currentPeriod.customers.reduce((acc: PeriodSummary, el) => {
		return {
			recyclingRate: acc.recyclingRate + el.sortingDegree,
			wasteTypes: acc.wasteTypes + el.wasteTypes.length,
			throws: acc.throws + el.totalOccurrences,
			residualWaste: acc.residualWaste + el.totalResidualWasteWeight.quantity,
			totalWeight: acc.totalWeight + el.totalWasteWeight.quantity,
		}
	}, initSummary)

	const prevActiveTenants = firstPeriod.customers.length
	const activeTenants = currentPeriod.customers.length

	const totalWeight = removeMoreThanTwoDecimals(sums.totalWeight / activeTenants)
	const residualWaste = removeMoreThanTwoDecimals(sums.residualWaste / activeTenants)

	const totalWeightPrev = removeMoreThanTwoDecimals(sumsPrevPeriod.totalWeight / prevActiveTenants)
	const residualWastePrev = removeMoreThanTwoDecimals(
		sumsPrevPeriod.residualWaste / prevActiveTenants
	)

	const recyclingRate = ((totalWeight - residualWaste) / totalWeight) * 100
	const prevRecyclingRate = ((totalWeightPrev - residualWastePrev) / totalWeightPrev) * 100

	return {
		recyclingRate,
		wasteTypes: removeMoreThanTwoDecimals(sums.wasteTypes / activeTenants),
		throws: removeMoreThanTwoDecimals(sums.throws / activeTenants),
		residualWaste,
		totalWeight,
		percentageChange: removeMoreThanTwoDecimals(recyclingRate - prevRecyclingRate),
	}
}

export const getTenantDetailsTableData = ({
	firstPeriod,
	currentPeriod,
	sanityData,
	wasteTypeConfig,
}: {
	firstPeriod: CustomersResponse
	currentPeriod: CustomersResponse
	sanityData: FetchedWasteTypes
	wasteTypeConfig: WasteTypeConfig[]
}): CollapsableTableDataInterface[] =>
	currentPeriod.customers
		.map((t, i) => {
			const details: WasteTypeDetails[] = t.wasteTypes.map(wt => ({
				wasteCode: wt.code,
				throws: wt.occurrences,
				totalWeight: wt.weight.quantity,
				wasteType:
					(wasteTypeConfig.find(wtc => wtc.wasteTypeCode === wt.code)?.name ||
						sanityData.find(el => el.id === wt.code)?.name) ??
					wt.code,
			}))

			const tenantLastPeriod = firstPeriod.customers.find(el => el.customerName === t.customerName)
			const previousAmount = (tenantLastPeriod?.sortingDegree ?? 0) * 100
			const currentSortingDegree = t.sortingDegree * 100
			const diff = currentSortingDegree - previousAmount

			return {
				details,
				id: t.customerName,
				name: t.customerName,
				recyclingRate: removeMoreThanTwoDecimals(t.sortingDegree * 100),
				throws: t.totalOccurrences,
				totalWeight: t.totalWasteWeight.quantity,
				wasteTypes: t.wasteTypes.length,
				residualWaste: t.totalResidualWasteWeight.quantity,
				percentageChange: diff,
			}
		})
		.sort(sortAlphabeticallyByProperty)
