import { ArrowIcon } from "Icons/Arrow"
import { FC } from "react"
import { FieldError } from "react-hook-form"
import Select from "react-select"
import { twMerge } from "tailwind-merge"

type StyledSelectProps = Omit<React.ComponentProps<typeof Select>, "classNames" | "onChange"> & {
	error?: FieldError
	onChange: (value: any) => void
}

/**
 * A styled select component to be used outside of forms. For use in forms, use the `SelectInput` component.
 */
export const StyledSelect: FC<StyledSelectProps> = props => {
	const { error } = props
	return (
		<Select
			{...props}
			unstyled
			classNames={{
				control: ({ isFocused, isDisabled }) =>
					twMerge(
						"text-sm border border-black rounded-none box-border h-10 py-2 px-3",
						isFocused && "border-2 shadow-black",
						isDisabled && "bg-grey1 text-grey6",
						error && "border border-carrotRed bg-lightPeach focus:outline-carrotRed"
					),
				indicatorSeparator: () => "hidden",
				menu: ({ placement }) =>
					twMerge("border-grey2 border rounded-none", placement === "bottom" ? "mt-1" : "mb-1"),
				option: ({ isDisabled, isSelected }) =>
					twMerge(
						"bg-white !text-sm text-black p-2 hover:bg-grey1",
						isDisabled && "cursor-not-allowed bg-grey1 text-grey6",
						isSelected && "bg-grey2"
					),
				placeholder: () => "text-sm text-grey4",
				menuList: () => "divide-y bg-white",
			}}
			components={{
				DropdownIndicator: ({ isFocused }) => (
					<ArrowIcon className={twMerge("h-3 w-3", isFocused && "transform rotate-180")} />
				),
			}}
		/>
	)
}
