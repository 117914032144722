import ErrorNotice from "components/ErrorNotice"
import { Card, CardComponentFilterLocation } from "components/pageCards/card"
import PerformanceRate from "components/PerformanceRate"
import { useTrans } from "translations"
import { mixedWastePerformance } from "../functions/dataHandling"
import { usePerformance } from "../hooks/usePerformance"
import {
	bestMixedWasteColumns,
	worstMixedWasteColumns,
} from "Utils/tableColumns/performanceColumns"

export const MixedWastePerformance = () => {
	const { t } = useTrans()
	const { filters, firstPeriod, currentPeriod, isLoading, isError, setActiveOptions } =
		usePerformance()

	const mixedWastePerformanceData =
		!firstPeriod || !currentPeriod ? [] : mixedWastePerformance(currentPeriod, firstPeriod)

	return (
		<Card
			title={"statisticsLabels:mixedWastePerformance"}
			filters={filters}
			onFilterOptionChange={setActiveOptions}
			filterLocation={CardComponentFilterLocation.RIGHT}
		>
			{isError ? (
				<ErrorNotice />
			) : (
				<PerformanceRate
					data={mixedWastePerformanceData.filter(el => el.rate !== 0)}
					isLoading={isLoading}
					sort="desc"
					bestColumns={bestMixedWasteColumns}
					worstColumns={worstMixedWasteColumns}
					bestHeading={t("statisticsLabels:leastMixedWasteHeading")}
					bestCaption={t("hints:leastMixedWasteCaption")}
					worstHeading={t("statisticsLabels:mostMixedWasteHeading")}
					worstCaption={t("hints:mostMixedWasteCaption")}
				/>
			)}
		</Card>
	)
}
