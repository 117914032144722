import { useEffect, useMemo, useState } from "react"
import { BrowserRouter as Router } from "react-router-dom"

import { ApolloProvider } from "@apollo/client"
import createApolloClient from "./apollo"

import { Spinner } from "./components"
import Main from "./Main"

import { useAuth0 } from "./Contexts"
import { ModalProvider } from "./Contexts/Modal"

import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { httpBatchLink } from "@trpc/client"
import { useApiStore } from "States/api"
import { useCommonEntitiesStore } from "States/commonEntities"
import { useTrans } from "translations"
import { requestSanity } from "Utils/api/sanity/request"
import {
	FetchedDownstreamHandlingTypes,
	FetchedHelpSections,
	FetchedLanguages,
	FetchedManufacturersTypes,
	FetchedTenantTypes,
	FetchedUpgradeTypes,
	FetchedWasteTypeClassificationSystems,
	FetchedWasteTypes,
	SmartIntegrations,
} from "Utils/api/sanity/types"
import "./App.css"
import "react-tabs/style/react-tabs.css"
import "moment/min/locales"
import { GlobalAlert } from "./components/AlertLine/GlobalAlert"
import { ContextMenu } from "./components/ContextMenu"
import { ConfirmationDialog } from "./components/Overlays/ConfirmationDialog"
import { isLocalhost } from "./Configs/config"
import { trpc } from "./Utils/trpc"
import { MultiSelectProvider } from "components/pageCards/multiListTable/multiSelectContext"
import { orderBy } from "lodash"
import { GraphQLClient } from "graphql-request"
import SuperJSON from "superjson"

export const Loading = () => {
	const { t, isTranslationLoaded } = useTrans()
	return (
		<div id="initapp">
			<Spinner />
			<h1>{isTranslationLoaded && t("hints:waitingForLoginServer")}</h1>
		</div>
	)
}

const reactQueryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: 0, // No query retries
			staleTime: 1000 * 60 * 60, // 1 hour cache
			cacheTime: 1000 * 60 * 60, // 1 hour cache
		},
	},
})

const App = () => {
	const { loading, isAuthenticated, loginWithRedirect, getTokenSilently } = useAuth0()!

	const { language, isTranslationLoaded } = useTrans()
	const { setApolloClient, setReactQueryClient, setGraphqlClient } = useApiStore()
	const {
		setWasteTypes,
		setUpgrades,
		setDownstreamHandling,
		setTenantCategories,
		setManufacturers,
		setLanguages,
		setSmartIntegrations,
		setWasteTypeClassificationSystems,
		setHelpSections,
		languages,
	} = useCommonEntitiesStore()

	// Get data fetching clients and auth0 token
	const apolloClient = useMemo(
		() => {
			const auth0AccessToken = createApolloClient(getTokenSilently)
			setApolloClient(auth0AccessToken)
			setReactQueryClient(reactQueryClient)
			return auth0AccessToken
		},
		// eslint-disable-next-line
		[loading]
	)

	const serverPort = process.env.REACT_APP_SERVER_PORT ?? "4000"

	const [queryClient] = useState(() => new QueryClient())
	const [trpcClient] = useState(() =>
		trpc.createClient({
			transformer: SuperJSON,
			links: [
				httpBatchLink({
					url: isLocalhost ? `http://localhost:${serverPort}/api/trpc` : "/api/trpc",
					// optional
					async headers() {
						return {
							language,
							authorization: await getTokenSilently(),
						}
					},
				}),
			],
		})
	)

	const proxyPort = process.env.REACT_APP_PROXY_PORT ?? "8010"

	useMemo(
		async () => {
			if (!isAuthenticated) return
			try {
				const token = await getTokenSilently()
				if (!token) return
				const baseUrl = isLocalhost ? `http://localhost:${proxyPort}/proxy` : ""
				setGraphqlClient(
					new GraphQLClient(`${baseUrl}/publicgraphql`, {
						headers: {
							authorization: `Bearer ${token}`,
						},
					})
				)
			} catch (e: any) {
				if (e.error === "login_required") {
					loginWithRedirect({
						appState: {
							targetUrl: `${window.location.pathname}${window.location.search}`,
						},
					})
				}
			}
		},
		// eslint-disable-next-line
		[isAuthenticated, proxyPort]
	)

	useEffect(() => {
		if (reactQueryClient) {
			requestSanity({ query: "languages" })
				.then(data => setLanguages(orderBy(data as FetchedLanguages, "code")))
				.catch(console.error)
		}
	}, [setLanguages])

	// Fetch and store container types
	useEffect(() => {
		if (reactQueryClient && languages) {
			requestSanity({ query: "wasteTypes", locale: language })
				.then(data => setWasteTypes(data as FetchedWasteTypes))
				.catch(console.error)
			requestSanity({ query: "upgradesTypes", locale: language })
				.then(data => setUpgrades(data as FetchedUpgradeTypes))
				.catch(console.error)
			requestSanity({ query: "downstreamHandlingTypes", locale: language })
				.then(data => setDownstreamHandling(data as FetchedDownstreamHandlingTypes))
				.catch(console.error)
			requestSanity({ query: "tenantTypes", locale: language })
				.then(data => setTenantCategories(data as FetchedTenantTypes))
				.catch(console.error)
			requestSanity({ query: "manufacturers", locale: language })
				.then(data => setManufacturers(data as FetchedManufacturersTypes))
				.catch(console.error)
			requestSanity({ query: "smartIntegrations", locale: language })
				.then(data => setSmartIntegrations(data as SmartIntegrations))
				.catch(console.error)
			requestSanity({ query: "wasteTypeClassificationSystems", locale: language })
				.then(data =>
					setWasteTypeClassificationSystems(data as FetchedWasteTypeClassificationSystems)
				)
				.catch(console.error)
			requestSanity({ query: "helpSections", locale: language })
				.then(data => setHelpSections(data as FetchedHelpSections))
				.catch(console.error)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reactQueryClient, setWasteTypes, language, languages])

	const clientsInitialized = useMemo(
		() =>
			Boolean(apolloClient && queryClient && trpcClient && reactQueryClient && isTranslationLoaded),
		[apolloClient, queryClient, trpcClient, isTranslationLoaded]
	)

	if (loading) {
		console.log("Waiting for auth0 to initialize")
		return <Loading />
	}

	if (!isAuthenticated) {
		console.log("Is not authenticated, need to log in")
		loginWithRedirect({
			appState: {
				targetUrl: `${window.location.pathname}${window.location.search}`,
			},
		})
		return <></>
	}

	if (!clientsInitialized) {
		console.log("Waiting for clients to initialize")
		console.log(`Apollo client: ${!!apolloClient}`)
		console.log(`Query client: ${!!queryClient}`)
		console.log(`TRPC client: ${!!trpcClient}`)
		console.log(`React Query client: ${!!reactQueryClient}`)
		return <Loading />
	}

	return (
		<trpc.Provider client={trpcClient} queryClient={queryClient}>
			<QueryClientProvider client={queryClient}>
				<ApolloProvider client={apolloClient}>
					<QueryClientProvider client={reactQueryClient}>
						<Router>
							<MultiSelectProvider>
								<ModalProvider>
									<Main />
								</ModalProvider>
							</MultiSelectProvider>
							<ConfirmationDialog />
							<ContextMenu />
							<GlobalAlert />
						</Router>
					</QueryClientProvider>
				</ApolloProvider>
			</QueryClientProvider>
		</trpc.Provider>
	)
}

export default App
