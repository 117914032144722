import { useModal } from "Contexts"
import { LoadingIndicator } from "Icons/loadingIndicator"
import { useGlobalAlert } from "States/globalAlert"
import { trpc } from "Utils/trpc"
import ModalContainer from "components/modalContainer"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { Translate, useTrans } from "translations"
import Magnifier from "Icons/Magnifier"
import classNames from "classnames"
import { Button } from "components/button"
import { TableWithAutocomplete } from "./TableWithAutocomplete"
import { cloneDeep, set } from "lodash"
import { IdentityKey } from "admin-client-server/src/utils/identitiesUtils"
import { NormalizedCustomerIdentities } from "admin-client-server/src/coreApi/models/RealEstate"
import { AccessTokenColumn, AccessTokenRow, FieldType, IdentityStatus, IdentityType } from "./types"
import { getAutocompleteRow, getDefaultRow, getTableColumns, getTableRows } from "./functions"

type Props = {
	customerId: string
	customerName: string
	refetch?: () => Promise<any>
}

export const AccessTokensModal: FC<Props> = ({ customerId, refetch }) => {
	const { t } = useTrans()
	const { hideModal } = useModal()
	const [isRefetching, setIsRefetching] = useState(false)
	const { setGlobalAlert } = useGlobalAlert()
	const [search, setSearch] = useState("")
	const [existingRows, setExistingRows] = useState<AccessTokenRow[]>([])
	const [newRows, setNewRows] = useState<AccessTokenRow[]>([])

	const trpcUtils = trpc.useUtils()

	const {
		data: allIdentitiesData,
		isLoading: isLoadingIdentities,
		isError,
		hasNextPage,
		fetchNextPage,
		isFetchingNextPage,
	} = trpc.identities.get.useInfiniteQuery(
		{ customerIds: [customerId], limit: 100 },
		{
			getNextPageParam: lastPage => lastPage.nextCursor,
			cacheTime: 0,
		}
	)

	const identities = useMemo(
		() => allIdentitiesData?.pages.flatMap(page => page.items) as NormalizedCustomerIdentities,
		[allIdentitiesData]
	)

	useEffect(() => {
		if (identities) {
			const tableRows = getTableRows(identities)
			setExistingRows(tableRows)
		}
	}, [identities])

	const filteredIdentities = useMemo(
		() =>
			[...newRows, ...existingRows]?.filter((row: any) =>
				search
					? [row.iso.value, row.em.value, row.printed.value].some((value: any) => {
							return value
								?.toString()
								.toLowerCase()
								.includes(search?.toLowerCase())
					  })
					: true
			),
		[newRows, existingRows, search]
	)

	const { mutateAsync: createIdentitiesMutation, isLoading: isCreatingIdentity } =
		trpc.identities.create.useMutation({
			trpc: {
				context: {
					skipBatch: true, // Update needs to be done after create
				},
			},
		})

	const { mutateAsync: addIdentitiesMutation, isLoading: isAddingIdentities } =
		trpc.identities.addToCustomer.useMutation({
			trpc: {
				context: {
					skipBatch: true, // Update needs to be done after add
				},
			},
		})
	const { mutateAsync: updateStatusMutation, isLoading: isUpdatingStatues } =
		trpc.identities.updateStatuses.useMutation({
			trpc: {
				context: {
					skipBatch: true, // Delete needs to be done after update
				},
			},
		})
	const { mutateAsync: deleteIdentitiesMutation, isLoading: isDeletingIdentites } =
		trpc.identities.delete.useMutation()

	const isLoading =
		isLoadingIdentities ||
		isCreatingIdentity ||
		isUpdatingStatues ||
		isDeletingIdentites ||
		isAddingIdentities ||
		isRefetching

	const onSubmit = useCallback(async () => {
		try {
			// add found identities

			const identitiesToAdd = newRows?.filter(row => row.identityId)
			if (identitiesToAdd?.length) {
				await addIdentitiesMutation({
					customerId,
					identityIds: identitiesToAdd.map(el => el.identityId!),
				})
			}
			// Create new identities
			const identitiesToCreate = newRows?.filter(row => !row.identityId)
			if (identitiesToCreate?.length) {
				await createIdentitiesMutation({
					customerId,
					identities: identitiesToCreate.map(identity => ({
						identityType: identity.em.value ? IdentityType.ISOEM : IdentityType.ISO,
						iso: identity.iso.value,
						em: identity.em?.value,
						printed: identity.printed.value,
						status: identity.status.value,
					})),
				})
			}
			const identitiesToEdit = existingRows?.filter(
				at =>
					identities?.find(
						identity => identity.iso === at.iso.value && identity.status !== at.status.value
					)
			)

			// Done after create since we need to update the status of the new identities
			if (identitiesToAdd?.length || identitiesToCreate?.length || identitiesToEdit?.length) {
				await updateStatusMutation({
					customerId,
					statusUpdates: [...identitiesToAdd, ...identitiesToCreate, ...identitiesToEdit].map(
						identity => ({
							id: identity.iso.value,
							externalKeyName: "ISO",
							status: identity.status.value ?? IdentityStatus.ACTIVE,
						})
					),
				})
			}
			// Delete identities
			const identitiesToDelete = identities?.filter(
				identity => !existingRows?.find(i => i.iso.value === identity.iso)
			)
			if (identitiesToDelete?.length) {
				await deleteIdentitiesMutation({
					customerId,
					ids: identitiesToDelete.map(identity => identity.id!),
				})
			}

			setIsRefetching(true)
			await refetch?.()
			setIsRefetching(false)

			setGlobalAlert({
				type: "success",
				message: "systemMessages:accessTokensUpdated",
			})
			hideModal()
		} catch (error: Error | any) {
			console.error(error)
			setGlobalAlert({
				type: "error",
				message: "systemMessages:accessTokensUpdateFailed",
				instructions: error?.message,
			})
		}
	}, [
		newRows,
		existingRows,
		addIdentitiesMutation,
		customerId,
		createIdentitiesMutation,
		deleteIdentitiesMutation,
		hideModal,
		refetch,
		setGlobalAlert,
		updateStatusMutation,
		identities,
	])

	const columns: AccessTokenColumn[] = useMemo(() => getTableColumns(t), [t])

	const getAutocompleteOptions = useCallback(
		async (key: IdentityKey, value: string) => {
			if (value) {
				const { options, existsAssigned } =
					await trpcUtils.client.identities.getAutocompleteOptionsWithValidation.query({
						key,
						value,
					})

				return {
					options,
					hasError: existsAssigned,
				}
			} else {
				return {
					options: [],
					hasError: false,
				}
			}
		},
		[trpcUtils]
	)

	const onAutocompleteOptionSelected = useCallback(
		(rowId: string, option: any) => {
			const updatedRows = newRows.map(row => {
				if (row.id === rowId) {
					return getAutocompleteRow(rowId, option)
				} else {
					return row
				}
			})

			setNewRows(updatedRows)
		},
		[newRows, setNewRows]
	)

	// Called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
	const fetchMoreOnBottomReached = useCallback(
		(containerRefElement?: HTMLDivElement | null) => {
			if (containerRefElement) {
				const { scrollHeight, scrollTop, clientHeight } = containerRefElement
				// Once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
				if (scrollHeight - scrollTop - clientHeight < 750 && !isFetchingNextPage && hasNextPage) {
					fetchNextPage()
				}
			}
		},
		[isFetchingNextPage, hasNextPage, fetchNextPage]
	)

	const addRow = useCallback(() => {
		const newRow = getDefaultRow()

		const updatedRows = [newRow, ...newRows]

		setNewRows(updatedRows)
	}, [newRows, setNewRows])

	const editCell = useCallback(
		(rowId: string, isNewRow: boolean, columnKey: FieldType, value: string, error: string = "") => {
			const updateData = (prevData: AccessTokenRow[]) => {
				const updatedData = cloneDeep(prevData)
				const rowIndex = updatedData.findIndex(el => el.id === rowId)

				let errorMessage = error
				if (!error) {
					const column = columns.find(col => col.name === columnKey)
					if (column?.unique) {
						updatedData.forEach((row, rowIndex) => {
							if (row[columnKey].value && row[columnKey].value === value && row.id !== rowId) {
								errorMessage = "errors:mustBeUnique"
								set(updatedData, `[${rowIndex}][${columnKey}].error`, errorMessage)
							}
						})
					}

					if (column?.required && !value) {
						errorMessage = "errors:required"
					}
				}

				const newCellValue = {
					value,
					error: errorMessage,
				}

				set(updatedData, `[${rowIndex}][${columnKey}]`, newCellValue)
				return updatedData
			}

			isNewRow ? setNewRows(rows => updateData(rows)) : setExistingRows(rows => updateData(rows))
		},
		[setNewRows, setExistingRows, columns]
	)

	const setCellError = useCallback(
		(isNewRow: boolean, rowId: string, columnKey: FieldType, error: string) => {
			const updateData = (prevData: AccessTokenRow[]) => {
				const updatedData = cloneDeep(prevData)
				const rowIndex = updatedData.findIndex(el => el.id === rowId)

				set(updatedData, `[${rowIndex}][${columnKey}].error`, error)

				return updatedData
			}

			isNewRow ? setNewRows(rows => updateData(rows)) : setExistingRows(rows => updateData(rows))
		},
		[setNewRows, setExistingRows]
	)

	const removeRow = useCallback(
		(row: AccessTokenRow) => {
			if (row.isNew) {
				const updatedRows = newRows.filter(r => r.id !== row.id)

				columns
					.filter(col => col.unique)
					.forEach(col => {
						updatedRows.forEach((row, rowIndex) => {
							const hasDuplicates = updatedRows
								.filter(r => r.id !== row.id)
								.some(r => r[col.name].value === row[col.name].value)

							if (row[col.name].value && hasDuplicates) {
								set(updatedRows, `[${rowIndex}][${col.name}].error`, "errors:mustBeUnique")
							} else {
								const hadDuplicateError = row[col.name].error === "errors:mustBeUnique"
								set(
									updatedRows,
									`[${rowIndex}][${col.name}].error`,
									hadDuplicateError ? "" : row[col.name].error
								)
							}
						})
					})

				setNewRows(updatedRows)
			} else {
				const updatedRows = existingRows.filter(r => r.id !== row.id)
				setExistingRows(updatedRows)
			}
		},
		[newRows, setNewRows, existingRows, setExistingRows, columns]
	)

	const isDirty = useMemo(
		() =>
			!!newRows.length ||
			existingRows.length !== identities?.length ||
			existingRows.some(row => {
				const prevRow = identities?.find(identity => identity.id === row.id)

				return row.status.value !== prevRow?.status
			}),
		[newRows, existingRows, identities]
	)
	const isValid = useMemo(
		() =>
			![...newRows, ...existingRows].some(
				row =>
					!row.iso.value ||
					["iso", "em", "printed"].some((key: string) => !!row[key as FieldType].error)
			),
		[newRows, existingRows]
	)

	return (
		<ModalContainer
			title={"actions:manageAccess"}
			onCancel={hideModal}
			onConfirm={() => onSubmit()}
			onConfirmLoading={isLoading}
			showDiscardModal={isDirty}
			onConfirmDisabled={!isValid}
			onScroll={e => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
		>
			<div className="mb-5 min-w-[800px] max-w-[1000px]">
				<div className="bg-grey1 p-5 mb-10">
					<h2 className="mb-2 font-dmSans text-base font-medium">
						{t("hints:manageAccessKeysTitle")}
					</h2>
					<Translate
						i18nKey={"hints:manageAccessKeysDescription"}
						components={[<p className="text-sm" />]}
					/>
				</div>
				{!isError && isLoadingIdentities && (
					<div className="flex justify-center">
						<LoadingIndicator />
					</div>
				)}
				{isError && (
					<p className="text-red-500 text-sm font-bold">
						{t("systemMessages:loadingAccessTokensFailed")}
					</p>
				)}
				{!isError && !isLoadingIdentities && (
					<>
						<div className="relative w-full mb-6">
							<p className="text-sm font-bold mb-1">{t("hints:searchForAccessTokens")}</p>
							<Magnifier className="absolute mt-2.5 ml-2 text-grey6" width={12} height={12} />
							<input
								onChange={e => setSearch(e.target.value)}
								type="text"
								placeholder={t("genericLabels:searchbarPlaceholder")}
								className={classNames(
									"rounded-none h-8 w-64 py-[7.5px] box-border border border-black pl-6 pr-3 font-dmSans text-sm placeholder:text-grey6 disabled:border-grey3 disabled:text-grey6",
									"focus:outline focus:outline-2 focus:outline-offset-[-2px]"
								)}
							/>

							<div className="float-right">
								<Button label="actions:addRow" onClick={addRow} type="button" color="secondary" />
							</div>
						</div>
						<TableWithAutocomplete
							columns={columns}
							emptyMessage={t("systemMessages:noAccessTokenAdded")}
							data={filteredIdentities}
							getAutocompleteOptions={getAutocompleteOptions}
							onAutocompleteOptionSelected={onAutocompleteOptionSelected}
							editCell={editCell}
							setCellError={setCellError}
							removeRow={removeRow}
						/>
					</>
				)}
			</div>
		</ModalContainer>
	)
}
