import { WasteType } from "admin-client-server/src/coreApi/models/Common"
import { FilterSort, FilterSortMenuType, Option } from "./types"
import { FetchedTenantTypes, FetchedWasteTypes } from "Utils/api/sanity/types"
import { orderBy } from "lodash"
import { OPTION_ALL } from "./constants"
import { WasteTypeConfig } from "pages/configuration/useWasteTypeConfig"

export enum FilterTimeFrameValue {
	TODAY = "today",
	YESTERDAY = "yesterday",
	LAST_30_DAYS = "last30days",
	YEAR_TO_DATE = "yearToDate",
	LAST_6_FULL_MONTHS = "last6FullMonths",
	LAST_FULL_YEAR = "lastFullYear",
	LAST_MONTH = "lastMonth",
	LAST_YEAR = "lastYear",
	LAST_3_MONTHS = "last3Months",
	LAST_6_MONTHS = "last6Months",
	LAST_12_MONTHS = "last12Months",
	SINCE_BEGINNING = "sinceBeginning",
	LAST_WEEK = "lastWeek",
}

export const createTimeFilters = (
	filterProps?: Partial<Omit<FilterSort, "options"> & { defaultValue: FilterTimeFrameValue }>,
	options?: FilterTimeFrameValue[]
): FilterSort => {
	const availableOptions = [
		{ option: "periods:today", value: FilterTimeFrameValue.TODAY, translate: true },
		{ option: "periods:7days", value: FilterTimeFrameValue.LAST_WEEK, translate: true },
		{ option: "periods:last30days", value: FilterTimeFrameValue.LAST_30_DAYS, translate: true },
		{ option: "periods:lastMonth", value: FilterTimeFrameValue.LAST_MONTH, translate: true },
		{ option: "periods:yearToDate", value: FilterTimeFrameValue.YEAR_TO_DATE, translate: true },
		{ option: "periods:lastYear", value: FilterTimeFrameValue.LAST_YEAR, translate: true },
		{
			option: "periods:last6FullMonths",
			value: FilterTimeFrameValue.LAST_6_FULL_MONTHS,
			translate: true,
		},
		{ option: "periods:lastFullYear", value: FilterTimeFrameValue.LAST_FULL_YEAR, translate: true },
		{
			option: "periods:sinceBeginning",
			value: FilterTimeFrameValue.SINCE_BEGINNING,
			translate: true,
		},
	] as { option: string; value: FilterTimeFrameValue; translate?: boolean }[]

	const filteredOptions = options?.length
		? availableOptions.filter(({ value }) => options.includes(value))
		: availableOptions

	return {
		id: "period",
		type: "filter",
		menuType: FilterSortMenuType.Select,
		title: "filterLabels:period",
		searchEnabled: false,
		...filterProps,
		options: filteredOptions,
	}
}

export const createFractionFilter = (
	fractionList: WasteType[],
	wasteTypes: FetchedWasteTypes | null,
	wasteTypeConfig: WasteTypeConfig[]
): FilterSort => {
	const mappedFractions =
		fractionList?.map(fraction => {
			const wasteType = wasteTypes?.find(({ id }: { id: any }) => id === fraction.code)
			return {
				option: wasteType
					? `${
							wasteTypeConfig.find(el => el.wasteTypeCode === fraction.code)?.name || wasteType.name
					  } (${fraction.code})`
					: fraction.code,
				value: fraction.code,
			}
		}) ?? []

	const sortedFractions = orderBy(mappedFractions, "option")

	return {
		id: "fractionId",
		type: "filter",
		menuType: FilterSortMenuType.Select,
		options: [
			{ option: "filterLabels:allWasteTypes", value: "all", translate: true },
			...sortedFractions,
		],
		title: "entities:wasteTypes",
		defaultValue: OPTION_ALL,
	}
}

export const getTenantCategoryOptions = (tenantCategories: FetchedTenantTypes | null): Option[] => [
	{ option: "filterLabels:allCustomerTypes", value: "all", translate: true },
	...(tenantCategories?.map(({ name, id }) => ({ value: id, option: name })) || []),
]
