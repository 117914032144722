import { CardFilterTimeFrameIso } from "components/pageCards/filterSort/filterTimeFrame"
import moment from "moment"
import { translate } from "translations/functions/hook"
import { FetchedWasteTypes } from "./api/sanity/types"

export const getFileName = (titleKey: string, timeframe: CardFilterTimeFrameIso) => {
	const startDate = moment(timeframe.startTimeISO).format("YYYYMMDD")
	const endDate = moment(timeframe.endTimeISO || moment().toISOString()).format("YYYYMMDD")

	const title = `${translate(titleKey)}_${startDate}-${endDate}`

	return title
}

export const getWasteTypeName = (sanityWasteTypes: FetchedWasteTypes, wt: string) =>
	sanityWasteTypes?.find(swt => swt.id === wt)?.name || ""
