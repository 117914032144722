import { CardFilterTimeFrameIso } from "components/pageCards/filterSort/filterTimeFrame"
import ExcelJS from "exceljs"
import { saveAs } from "file-saver"
import { sum } from "lodash"
import moment from "moment"
import { translate } from "translations/functions/hook"
import { FetchedWasteTypes } from "Utils/api/sanity/types"
import { TenantType } from "."
import { getFileName, getWasteTypeName } from "Utils/commonExportFunctions"
import { formatWeight } from "Utils/formatFunctions"
import { EXCEL_FORMAT } from "constants/general"
import { sortAlphabeticallyByProperty } from "Utils/sorting"
import { WasteTypeConfig } from "pages/configuration/useWasteTypeConfig"

const COL_WIDTH = 20
const ROW_HEIGHT = 25

const getLetterAtOffset = (offset: number) => {
	const code = 65 + offset

	// if we have more than 26 columns, we need to start using double letters
	if (code > 90) {
		return `A${String.fromCharCode(code - 26)}`
	}

	return String.fromCharCode(code)
}

type ExcelColumn = {
	header: string
	key: string
}

type ExcelProps = {
	tenants: TenantType[]
	wasteTypes: string[]
	nrSelected: number
	nrTotal: number
	timeframe: CardFilterTimeFrameIso
	sanityWasteTypes: FetchedWasteTypes
	wasteTypeConfig: WasteTypeConfig[]
}

const getSelection = (nrSelected: number, nrTotal: number, tenants: TenantType[]) => {
	if (tenants.length === 1) {
		return tenants[0].name
	}

	if (!nrSelected || nrSelected === nrTotal) {
		return translate("exportLabels:allCustomers")
	}

	return translate("exportLabels:nrTenants", { nrTenants: nrSelected })
}

export const getSortedWasteTypes = (
	filteredWasteTypes: string[],
	wasteTypeConfig: WasteTypeConfig[],
	sanityWasteTypes: FetchedWasteTypes
) => {
	const typesWithName = filteredWasteTypes
		.map(wt => ({
			code: wt,
			name:
				wasteTypeConfig.find(el => el.wasteTypeCode === wt)?.name ||
				getWasteTypeName(sanityWasteTypes!, wt),
		}))
		.sort(sortAlphabeticallyByProperty)
		.map(el => el.code)

	return typesWithName
}

const getColumns = ({
	wasteTypes,
	sanityWasteTypes,
	wasteTypeConfig,
}: {
	wasteTypes: string[]
	sanityWasteTypes: FetchedWasteTypes
	wasteTypeConfig: WasteTypeConfig[]
}) => {
	const columns: ExcelColumn[] = [
		{
			header: translate("entities:customer"),
			key: "name",
		},
		{
			header: translate("genericLabels:tenantId"),
			key: "id",
		},
	]

	wasteTypes.forEach(wt => {
		columns.push({
			header: `${
				wasteTypeConfig.find(el => el.wasteTypeCode === wt)?.name ||
				getWasteTypeName(sanityWasteTypes, wt)
			} (${wt})`,
			key: wt,
		})
	})

	columns.push({
		header: translate("exportLabels:totalPerTenant"),
		key: "total",
	})

	return columns
}

const generateExcelFile = ({
	tenants,
	wasteTypes,
	nrSelected,
	nrTotal,
	timeframe,
	sanityWasteTypes,
	wasteTypeConfig,
}: ExcelProps) => {
	const workbook = new ExcelJS.Workbook()
	const sheet = workbook.addWorksheet(
		getFileName("exportLabels:reportingSheetFileName", timeframe),
		{
			views: [{ state: "frozen", ySplit: 3, xSplit: 2 }],
		}
	)

	const period = `${moment(timeframe.startTimeISO).format("DD.MM.YYYY")}-${moment(
		timeframe.endTimeISO || moment().toISOString()
	).format("DD.MM.YYYY")}`
	const selection = getSelection(nrSelected, nrTotal, tenants)

	// fill in time period text
	sheet.getColumn("A").width = COL_WIDTH
	sheet.getColumn("B").width = COL_WIDTH - 5
	sheet.getRow(1).height = ROW_HEIGHT
	sheet.mergeCells("A1:B1")
	sheet.getCell("A1").value = translate("exportLabels:timePeriod", { period })
	sheet.getCell("A1").style = {
		font: {
			italic: true,
		},
	}

	// fill in selection text
	sheet.getRow(2).height = ROW_HEIGHT
	sheet.mergeCells("A2:B2")
	sheet.getCell("A2").value = translate("exportLabels:selection", { selection })

	// fill in main title
	if (wasteTypes.length > 1) {
		sheet.mergeCells(`C1:${getLetterAtOffset(wasteTypes.length + 2)}2`)
	} else {
		sheet.mergeCells(`C1:F2`)
	}
	sheet.getCell("C1").value = translate("exportLabels:amountPerTenant")
	sheet.getCell("C1").style = {
		font: {
			bold: true,
			size: 24,
		},
	}

	const columns = getColumns({
		wasteTypes,
		sanityWasteTypes,
		wasteTypeConfig,
	})

	// fill in column titles
	sheet.getRow(3).height = ROW_HEIGHT
	columns.forEach((c, i) => {
		const cell = `${getLetterAtOffset(i)}3`
		sheet.getColumn(getLetterAtOffset(i)).width = COL_WIDTH
		sheet.getCell(cell).value = c.header
		sheet.getCell(cell).style = {
			font: {
				bold: true,
			},
		}
	})

	// add rows
	tenants.forEach((tenant, rowIndex) => {
		sheet.getRow(rowIndex).height = ROW_HEIGHT - 5
		columns.forEach((col, colIndex) => {
			const cell = `${getLetterAtOffset(colIndex)}${rowIndex + 4}`
			sheet.getCell(cell).value = tenant[col.key] || "-"

			if (col.key === "total") {
				sheet.getCell(cell).style = {
					font: {
						bold: true,
					},
				}
			}
		})
	})

	// add total row
	if (tenants.length > 1) {
		const totalRowIndex = tenants.length + 4
		sheet.getRow(totalRowIndex).height = ROW_HEIGHT
		sheet.mergeCells(`A${totalRowIndex}:B${totalRowIndex}`)
		sheet.getCell(`A${totalRowIndex}`).value = translate("exportLabels:totalPerWasteType")
		sheet.getCell(`A${totalRowIndex}`).style = {
			font: {
				bold: true,
				size: 14,
			},
			border: {
				top: {
					style: "medium",
				},
			},
		}

		sheet.getCell(`B${totalRowIndex}`).style = {
			border: {
				top: {
					style: "medium",
				},
			},
		}

		columns.slice(2).forEach((c, i) => {
			const cell = `${getLetterAtOffset(i + 2)}${totalRowIndex}`
			const value = sum(tenants.map(t => +t[c.key]))

			sheet.getCell(cell).value = formatWeight(value)
			sheet.getCell(cell).style = {
				font: {
					bold: true,
					size: 14,
				},
				border: {
					top: {
						style: "medium",
					},
				},
			}
		})
	}

	return workbook
}

export const exportExcel = async (props: ExcelProps) => {
	const fileTitle = getFileName("exportLabels:reportingFileName", props.timeframe)
	const workbook = generateExcelFile(props)

	const xls = await workbook.xlsx.writeBuffer()
	saveAs(
		new Blob([xls], {
			type: EXCEL_FORMAT,
		}),
		fileTitle
	)
}
