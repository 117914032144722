import { CardFilterTimeFrameIso } from "components/pageCards/filterSort/filterTimeFrame"
import ExcelJS from "exceljs"
import { saveAs } from "file-saver"
import { translate } from "translations/functions/hook"
import { FetchedWasteTypes } from "Utils/api/sanity/types"
import { Customer } from "Utils/api/datawarehouse/responseTypes"
import moment from "moment"
import { getFileName, getWasteTypeName } from "Utils/commonExportFunctions"
import { formatWeight } from "Utils/formatFunctions"
import { sortAlphabeticallyByProperty } from "Utils/sorting"
import { WasteTypeConfig } from "pages/configuration/useWasteTypeConfig"

type CsvProps = {
	tenants: Customer[]
	wasteTypes: string[]
	timeframe: CardFilterTimeFrameIso
	sanityWasteTypes: FetchedWasteTypes
	wasteTypeConfig: WasteTypeConfig[]
}

type ColumnType = {
	header: string
	key: string
}

const CSV_COLUMNS: ColumnType[] = [
	{
		header: "exportLabels:dateFrom",
		key: "from",
	},
	{
		header: "exportLabels:dateTo",
		key: "to",
	},
	{
		header: "entities:customer",
		key: "tenantName",
	},
	{
		header: "genericLabels:tenantId",
		key: "tenantId",
	},
	{
		header: "exportLabels:wasteType",
		key: "wasteTypeName",
	},
	{
		header: "exportLabels:wasteTypeCode",
		key: "wasteTypeCode",
	},
	{
		header: "exportLabels:amount",
		key: "amount",
	},
]

type RowType = {
	from: string
	to: string
	tenantName: string
	tenantId: string
	wasteTypeName: string
	wasteTypeCode: string
	amount: number
}

const getRowData = ({
	tenants,
	wasteTypes,
	timeframe,
	sanityWasteTypes,
	wasteTypeConfig,
}: CsvProps) => {
	const rows: RowType[] = []

	tenants.forEach(tenant => {
		tenant.wasteTypes
			.filter(wt => wasteTypes.includes(wt.code))
			.map(wt => ({
				...wt,
				name:
					wasteTypeConfig.find(el => el.wasteTypeCode === wt.code)?.name ||
					getWasteTypeName(sanityWasteTypes, wt.code),
			}))
			.sort(sortAlphabeticallyByProperty)
			.forEach(wt => {
				rows.push({
					from: timeframe.startTimeISO,
					to: timeframe.endTimeISO || moment().toISOString(),
					tenantName: tenant.customerName,
					tenantId: tenant.customerId,
					wasteTypeName: wt.name,
					wasteTypeCode: wt.code,
					amount: formatWeight(wt.weight.quantity),
				})
			})
	})

	return rows
}

const generateCsvFile = (props: CsvProps) => {
	const workbook = new ExcelJS.Workbook()
	const sheet = workbook.addWorksheet(
		getFileName("exportLabels:reportingSheetFileName", props.timeframe)
	)
	sheet.columns = CSV_COLUMNS.map((column: ColumnType) => ({
		header: translate(column.header),
		key: column.key,
		width: 20,
		style: {
			font: {
				bold: true,
			},
		},
	}))

	const rowData: RowType[] = getRowData(props)
	sheet.addRows(rowData)

	return workbook
}

export const exportCsv = async (props: CsvProps) => {
	const fileName = getFileName("exportLabels:reportingFileName", props.timeframe)
	const workbook = generateCsvFile(props)

	const csv = await workbook.csv.writeBuffer()
	saveAs(new Blob([csv], { type: "text/csv;charset=UTF-8" }), fileName)
}
