import classNames from "classnames"
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react"
import { useTrans } from "translations"
import { CollapsedFilterSortButton } from "./CollapsedFilterSortButton"
import { FilterSortButton } from "./FilterSortButton"
import { useFilterSort } from "./FilterSortContext"
import { FilterSort, Option } from "./types"
import { Card, CardComponentFilterLocation } from "../card"
import { usePdfPreview } from "pages/home/pdfPreview"

type FilterSortsProps = {
	filterSorts: FilterSort[]
	collapsed?: boolean
	alwaysExpanded?: boolean
} & Pick<React.ComponentProps<typeof Card>, "filterLocation">

/**
 * Renders the FilterSort "bar" component.
 *
 * @param filterSorts - The array of filters/sorts.
 * @param collapsed - A boolean indicating whether the filters/sorts are collapsed or not.
 * @param cardHeaderContainerWidth - The size of the card header container. Used to determine if the filters/sorts should be collapsed or not.
 */
export const FilterSorts: React.FC<FilterSortsProps> = ({
	filterSorts,
	collapsed,
	filterLocation,
	alwaysExpanded = false,
}) => {
	const { t } = useTrans()
	const { activeOptions, updateActiveOptions, resetAll } = useFilterSort()
	const { pdfPreviewOpen } = usePdfPreview()
	const [windowSize, setWindowSize] = useState([0, 0])
	const filtersContainer = useRef<HTMLDivElement | null>(null)

	useLayoutEffect(() => {
		function onResize() {
			setWindowSize([window.innerWidth, window.innerHeight])
		}
		window.addEventListener("resize", onResize)
		onResize()
		return () => window.removeEventListener("resize", onResize)
	}, [])

	const filterContainerSize = useMemo(() => {
		const { width = 0 } = filtersContainer.current?.getBoundingClientRect() || {}
		return width
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersContainer, windowSize])

	const filtersLength = useMemo(
		() => filterSorts.filter(f => f.type === "filter").length,
		[filterSorts]
	)

	const hasNonDefaultActiveFilters = useMemo(() => {
		return Object.keys(activeOptions).some(id => {
			const filterSort = filterSorts.find(filterSort => filterSort.id === id)
			return activeOptions[id].some(option => option.value !== filterSort?.defaultValue)
		})
	}, [activeOptions, filterSorts])

	const shouldCollapse = useMemo(() => {
		// 1 filter can be max 200 + 8px gap <= 208
		return (
			!alwaysExpanded &&
			(collapsed || (filtersLength > 1 && Math.floor(filterContainerSize / 208) < filtersLength))
		)
	}, [filterContainerSize, filtersLength, collapsed, alwaysExpanded])

	const allOptionsLength = useMemo(
		() =>
			filterSorts.reduce((acc, filterSort) => [...acc, ...filterSort.options], [] as Option[])
				.length,
		[filterSorts]
	)

	useEffect(() => {
		// check to see if any active option from URL needs to be updated with data from filters (option and translate)
		const activeOptionsToUpdate = Object.entries(activeOptions).reduce(
			(acc, [id, options]) => {
				const filterSort = filterSorts.find(filterSorts => filterSorts.id === id)
				const updatedOptions = options.map(option => {
					if (!option.option) {
						const filterSortOption = filterSort?.options.find(
							filterOption => filterOption.value === option.value
						)
						return filterSortOption || option
					}
					return option
				})
				acc[id] = updatedOptions
				return acc
			},
			{} as typeof activeOptions
		)
		updateActiveOptions(activeOptionsToUpdate)
		// Should only run when new filter/sort options become available
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allOptionsLength])

	const filters = useMemo(() => filterSorts.filter(f => f.type === "filter"), [filterSorts])
	const sorting = useMemo(() => filterSorts.find(f => f.type === "sort"), [filterSorts])

	return (
		<div
			ref={filtersContainer}
			className={classNames(
				"flex gap-3 w-full shrink",
				filterLocation === CardComponentFilterLocation.RIGHT && "justify-end"
			)}
		>
			{!!filters.length && (
				<div className="flex gap-2 h-8">
					{shouldCollapse ? (
						<CollapsedFilterSortButton filterSorts={filters} filterLocation={filterLocation} />
					) : (
						filters.map(filterSort => (
							<FilterSortButton
								key={filterSort.id}
								filterLocation={filterLocation}
								{...filterSort}
							/>
						))
					)}
				</div>
			)}
			{!!sorting && (
				<FilterSortButton key={sorting.id} filterLocation={filterLocation} {...sorting} />
			)}
			{!pdfPreviewOpen &&
				hasNonDefaultActiveFilters &&
				filterLocation !== CardComponentFilterLocation.INSIDE &&
				filtersLength > 1 &&
				!shouldCollapse && (
					<button
						onClick={resetAll}
						className="px-3 text-grey6 h-8 border-l border-grey3 text-xs hover:underline"
					>
						{t("filterLabels:resetFilters")}
					</button>
				)}
		</div>
	)
}
