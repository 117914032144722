import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import { useTrans } from "translations"

export const NoAccessParentsForCustomersModal: React.FC = () => {
	const { t } = useTrans()
	const { hideModal } = useModal()

	return (
		<ModalContainer
			title={"errors:noAccessParents"}
			onConfirm={hideModal}
			onCancel={hideModal}
			onConfirmText="common:OK"
			disableCancelButton
		>
			<div className="pt-3 whitespace-pre">
				<p>{t("hints:noAccessParentsForCustomers")}</p>
			</div>
		</ModalContainer>
	)
}
