import { trpc } from "Utils/trpc"
import { useEffect, useMemo } from "react"
import { useTrans } from "translations"
import { combine } from "zustand/middleware"
import create from "zustand"
import { useTerminalsState } from "States/Terminals"
import { uniq } from "lodash"
import { useGlobalAlert } from "States/globalAlert"

export type WasteTypeConfig = {
	wasteTypeCode: string
	color: string | null
	name?: string
}

export type WasteTypeData = {
	wasteTypeCode: string
	names: {
		displayName: string
		locale: string
	}[]
	color: string | null
}

export const wasteTypeConfigStore = create<{
	wasteTypeConfig: WasteTypeConfig[]
	setWasteTypeConfig: (wasteTypeConfig: WasteTypeConfig[]) => void
}>(
	combine(
		{
			wasteTypeConfig: [],
		} as any,
		set =>
			({
				setWasteTypeConfig: (wasteTypeConfig: WasteTypeConfig[]) => set({ wasteTypeConfig }),
			}) as any
	)
)

export const useWasteTypeConfig = () => {
	const { language } = useTrans()
	const { setGlobalAlert } = useGlobalAlert()
	const { currentTerminal } = useTerminalsState()
	const { data: clientConfig, refetch: refetchClientConfig } =
		trpc.config.getClientWasteTypeConfig.useQuery(undefined, { enabled: !!currentTerminal?.id })
	const { data: terminalConfig, refetch: refetchTerminalConfig } =
		trpc.config.getTerminalWasteTypeConfig.useQuery(
			{ terminalId: currentTerminal?.id },
			{ enabled: !!currentTerminal?.id }
		)
	const { setWasteTypeConfig } = wasteTypeConfigStore()
	const { data: terminalColors, refetch: refetchTerminalColors } =
		trpc.config.getTerminalConfig.useQuery(
			{ terminalId: currentTerminal?.id },
			{ enabled: !!currentTerminal?.id }
		)

	const { mutate: updateTerminalConfig } = trpc.config.updateTerminalConfig.useMutation({
		onSuccess: () => {
			refetchTerminalColors()
			setGlobalAlert({
				type: "success",
				message: "systemMessages:changesSaved",
			})
		},
		onError: error => {
			setGlobalAlert({
				type: "error",
				message: "errors:failedSave",
				instructions: error.message,
			})
		},
	})

	const localizedClientConfig: WasteTypeConfig[] = useMemo(
		() =>
			clientConfig?.map(el => ({
				wasteTypeCode: el.wasteTypeCode,
				color: el.color,
				name: el.names.find(n => n.locale === language)?.displayName,
			})) || [],
		[clientConfig, language]
	)

	const localizedTerminalConfig: WasteTypeConfig[] = useMemo(
		() =>
			terminalConfig?.map(el => ({
				wasteTypeCode: el.wasteTypeCode,
				color: el.color,
				name: el.names.find(n => n.locale === language)?.displayName,
			})) || [],
		[terminalConfig, language]
	)

	const unifiedLocalizedConfig: WasteTypeConfig[] = useMemo(() => {
		const wasteTypesWithConfig = uniq([
			...localizedClientConfig.map(el => el.wasteTypeCode),
			...localizedTerminalConfig.map(el => el.wasteTypeCode),
		])

		const unifiedConfig = wasteTypesWithConfig.map(wasteTypeCode => {
			const clientConfig = localizedClientConfig.find(el => el.wasteTypeCode === wasteTypeCode)
			const terminalConfig = localizedTerminalConfig.find(el => el.wasteTypeCode === wasteTypeCode)

			return {
				wasteTypeCode,
				color: terminalConfig?.color || clientConfig?.color || "",
				name: terminalConfig?.name || clientConfig?.name,
			}
		})

		return unifiedConfig
	}, [localizedClientConfig, localizedTerminalConfig])

	useEffect(() => {
		setWasteTypeConfig(unifiedLocalizedConfig)
	}, [unifiedLocalizedConfig, setWasteTypeConfig])

	return {
		wasteTypeClientConfig: localizedClientConfig,
		wasteTypeTerminalConfig: localizedTerminalConfig,
		wasteTypeConfig: unifiedLocalizedConfig,
		clientConfig: clientConfig || [],
		terminalConfig: terminalConfig || [],
		refetchClientConfig,
		refetchTerminalConfig,
		terminalColors,
		refetchTerminalColors,
		updateTerminalConfig,
	}
}
