import { Card } from "components/pageCards/card"
import { getTimeFrame } from "components/pageCards/filterSort/filterTimeFrame"
import { useEffect, useMemo, useState } from "react"
import { useTerminalsState } from "States/Terminals"
import { useCommonEntitiesStore } from "States/commonEntities"
import { useDataWareHouse } from "Utils/api/datawarehouse/request"
import { getWastetypesInUse, getWastetypesNotUsed } from "./functions"
import { WasteTypesInUserTable } from "./WasteTypesInUserTable"
import { CategoryNameFilters } from "Utils/api/datawarehouse/types"
import { LoadingIndicator } from "Icons/loadingIndicator"
import { useAccessParents } from "pages/infrastructure/functions"
import { FilterTimeFrameValue } from "components/pageCards/filterSort/filterCreators"
import { useHomeLoadingState } from "pages/home/loadingState"
import { EmptyView } from "components/EmptyView/EmptyView"

interface WasteTypesInUseProps {
	limitNumberOfTypes?: boolean
}

export const WasteTypesInUseCard: React.FC = () => {
	return (
		<Card title={"circularityLabels:wasteTypeCategoriesInUse"} overflow="auto">
			<WasteTypesInUse />
		</Card>
	)
}

export const WasteTypesInUse: React.FC<WasteTypesInUseProps> = ({ limitNumberOfTypes = true }) => {
	const { currentTerminal } = useTerminalsState()
	const [timeFrame] = useState(getTimeFrame(FilterTimeFrameValue.LAST_30_DAYS))
	const { wasteTypes: sanityWasteTypes } = useCommonEntitiesStore()
	const { setWasteTypesInUseLoading } = useHomeLoadingState()

	const {
		data,
		isError: isDataError,
		isLoading: wasteTypesLoading,
	} = useDataWareHouse({
		endpoint: "terminal",
		terminalId: currentTerminal.id,
		filters: timeFrame,
	})

	const { manageAccessParentPageData: wasteRoomData, isLoading } = useAccessParents()

	const {
		data: aggregatedData,
		isError: isAggregatesError,
		isLoading: aggregatesLoading,
	} = useDataWareHouse({
		endpoint: "terminal-aggregates",
		filters: {
			...timeFrame,
			commercialTerminalCategory:
				currentTerminal.commercialRealEstateCategory as CategoryNameFilters,
		},
	})

	useEffect(() => {
		setWasteTypesInUseLoading(isLoading || wasteTypesLoading || aggregatesLoading)
	}, [setWasteTypesInUseLoading, isLoading, wasteTypesLoading, aggregatesLoading])

	const isError = isDataError || isAggregatesError

	const wasteTypesInUse = useMemo(
		() =>
			sanityWasteTypes && data ? getWastetypesInUse(data, wasteRoomData, sanityWasteTypes) : [],
		[data, wasteRoomData, sanityWasteTypes]
	)

	const wasteTypesNotInUse = useMemo(
		() =>
			aggregatedData && data && sanityWasteTypes
				? getWastetypesNotUsed(aggregatedData, data, wasteRoomData, sanityWasteTypes)
				: [],
		[aggregatedData, data, wasteRoomData, sanityWasteTypes]
	)

	return isError ? (
		<EmptyView type="failToFetch" className="min-h-[300px]" />
	) : isLoading ? (
		<LoadingIndicator />
	) : (
		<WasteTypesInUserTable
			avgTypes={12}
			typesInUse={wasteTypesInUse}
			futurePotential={wasteTypesNotInUse}
			limitNumberOfTypes={limitNumberOfTypes}
		/>
	)
}
