import { Cell, flexRender } from "@tanstack/react-table"
import { twMerge } from "tailwind-merge"
import { DataTableDataType, ExpandedColumnDef } from ".."

type TableCellProps = {
	cell: Cell<any, DataTableDataType>
	isSelected: boolean
}

export const TableCell: React.FC<TableCellProps> = ({ cell, isSelected }) => {
	const { rightAlign, stickyLeft, stickyRight } = cell.column.columnDef as ExpandedColumnDef

	const isCheckbox = cell.column.id === "checkbox"

	return (
		<td
			key={cell.id}
			className={twMerge(
				"overflow-hidden text-ellipsis whitespace-nowrap px-2.5 py-2.5",
				rightAlign && "justify-end text-end pr-8",
				stickyLeft && "sticky left-0 bg-white",
				stickyLeft && !isCheckbox && "left-8",
				stickyRight && "pr-4 sticky right-0 font-medium bg-grey1",
				isSelected && "bg-selectedColor",
				"group-hover:bg-hoverColor"
			)}
		>
			{flexRender(cell.column.columnDef.cell, cell.getContext())}
		</td>
	)
}
