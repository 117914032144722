import ErrorNotice from "components/ErrorNotice"
import { Card, CardComponentFilterLocation } from "components/pageCards/card"
import PerformanceRate from "components/PerformanceRate"
import { useTrans } from "translations"
import { percentagePerformance } from "../functions/dataHandling"
import { usePerformance } from "../hooks/usePerformance"
import {
	bestPerformanceColumns,
	worstPerformanceColumns,
} from "Utils/tableColumns/performanceColumns"

export const PercentagePerformance = () => {
	const { t } = useTrans()
	const { filters, currentPeriod, firstPeriod, isLoading, isError, setActiveOptions } =
		usePerformance()

	const percentagePerformanceData =
		!firstPeriod || !currentPeriod ? [] : percentagePerformance(currentPeriod, firstPeriod)

	return (
		<Card
			title={"statisticsLabels:recyclingRatePerformance"}
			filters={filters}
			onFilterOptionChange={setActiveOptions}
			filterLocation={CardComponentFilterLocation.RIGHT}
		>
			{isError ? (
				<ErrorNotice />
			) : (
				<PerformanceRate
					data={percentagePerformanceData.filter(el => el.rate !== 0 && el.rate !== 100)} // potential FIXME: https://carrot-tech.slack.com/archives/C045AKF5U7N/p1666718297155959?thread_ts=1666717811.918099&cid=C045AKF5U7N
					isLoading={isLoading}
					sort="asc"
					bestColumns={bestPerformanceColumns}
					worstColumns={worstPerformanceColumns}
					bestHeading={t("statisticsLabels:highestRecyclingRateHeading")}
					bestCaption={t("hints:highestRecyclingRateCaption")}
					worstHeading={t("statisticsLabels:lowestRecyclingRateHeading")}
					worstCaption={t("hints:lowestRecyclingRateCaption")}
				/>
			)}
		</Card>
	)
}
