import en from "date-fns/locale/en-US"
import nb from "date-fns/locale/nb"

export const getCurrentLocale = () => {
	const defaultLocale = ["nn-NO", "nb-NO", "nb"].includes(navigator.language) ? "nb" : "en"
	return window.localStorage.getItem("language.preference") || defaultLocale
}

export const DATE_PICKER_LOCALE: { [k: string]: Locale } = {
	en,
	nb,
}
