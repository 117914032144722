import { CardComponentFilterLocation } from "components/pageCards/card"
import { getTimeFrame } from "components/pageCards/filterSort/filterTimeFrame"
import {
	createTimeFilters,
	FilterTimeFrameValue,
} from "components/pageCards/filterSort/filterCreators"
import { ActiveOptions } from "components/pageCards/filterSort/FilterSortContext"
import { FilterSort } from "components/pageCards/filterSort/types"
import { INumberEntryProps, NumberCard } from "components/pageCards/numberCard"
import React, { useEffect, useMemo, useState } from "react"
import { useTerminalsState } from "States/Terminals"
import { useTrans } from "translations"
import { useDataWareHouse } from "Utils/api/datawarehouse/request"
import { AggregatedTerminalResponse, TerminalResponse } from "Utils/api/datawarehouse/responseTypes"
import { Terminal as TerminalRequest, TerminalAggregates } from "Utils/api/datawarehouse/types"
import { useConfig } from "api/hooks/useConfig"
import { useHomeLoadingState } from "pages/home/loadingState"

const defaultPeriod = FilterTimeFrameValue.LAST_30_DAYS

type Heading = {
	id: string
	heading: string
}

const getMWMData = (
	headings: Heading[],
	data?: AggregatedTerminalResponse
): INumberEntryProps[] => {
	if (!data) {
		return headings.map(h => ({ heading: h.heading, data: "-", suffix: "" }))
	}
	const { totalResidualWasteOccurrences, totalOccurrences, occurrenceBasedSortingDegree } =
		data.terminalAggregates

	const recycled = totalOccurrences - totalResidualWasteOccurrences
	const values: { [k: string]: string | number } = {
		residual: totalResidualWasteOccurrences,
		recycled,
		sortingRate: occurrenceBasedSortingDegree * 100,
	}

	const numbers: INumberEntryProps[] = headings.map(h => ({
		...h,
		data: values[h.id],
		suffix: h.id === "sortingRate" ? " %" : "",
	}))

	return numbers
}

const getRealEstateData = (headings: Heading[], data?: TerminalResponse): INumberEntryProps[] => {
	if (!data) {
		return headings.map(h => ({ heading: h.heading, data: "-", suffix: "" }))
	}
	const { totalWasteWeight, totalResidualWasteWeight, sortingDegree } = data.terminal
	const values: { [k: string]: string | number } = {
		residual: totalResidualWasteWeight.quantity,
		recycled: totalWasteWeight.quantity - totalResidualWasteWeight.quantity,
		sortingRate: sortingDegree * 100,
	}

	const numbers: INumberEntryProps[] = headings.map(h => ({
		...h,
		data: values[h.id],
		suffix: h.id === "sortingRate" ? " %" : "",
	}))

	return numbers
}

export const OverviewKeyNumbers: React.FC<{}> = () => {
	const { t } = useTrans()
	const { isMWM } = useConfig()
	const { setKeyNumbersLoading } = useHomeLoadingState()
	const [{ period: [selectedPeriod] = [] }, setActiveOptions] = useState<ActiveOptions<"period">>(
		{} as any
	)

	const filters: FilterSort[] = useMemo(
		() => [createTimeFilters({ defaultValue: defaultPeriod })],
		[]
	)

	const { currentTerminal } = useTerminalsState()

	const requestParameters = useMemo(() => {
		const timeFilter = getTimeFrame(
			(selectedPeriod?.value as FilterTimeFrameValue) ?? defaultPeriod
		)

		const props: TerminalAggregates | TerminalRequest = isMWM
			? {
					endpoint: "terminal-aggregates",
					filters: timeFilter,
			  }
			: {
					endpoint: "terminal",
					filters: timeFilter,
					terminalId: currentTerminal.id,
			  }

		return props
	}, [isMWM, selectedPeriod, currentTerminal])

	const { data, isLoading } = useDataWareHouse(requestParameters)

	useEffect(() => {
		setKeyNumbersLoading(isLoading)
	}, [setKeyNumbersLoading, isLoading])

	const headings: Heading[] = useMemo(
		() => [
			{
				id: "residual",
				heading: `${t("circularityLabels:residualWaste")} (${
					isMWM ? t("statisticsLabels:nrThrows").toLowerCase() : "kg"
				})`,
			},
			{
				id: "recycled",
				heading: `${t("circularityLabels:recycled")} (${
					isMWM ? t("statisticsLabels:nrThrows").toLowerCase() : "kg"
				})`,
			},
			{ id: "sortingRate", heading: t("circularityLabels:recyclingRate") },
		],
		[isMWM, t]
	)

	const view = useMemo(() => {
		let numbers = isMWM
			? getMWMData(headings, data as AggregatedTerminalResponse)
			: getRealEstateData(headings, data as TerminalResponse)

		return (
			<NumberCard
				title="circularityLabels:keyNumbers"
				numbers={numbers}
				filters={filters}
				isLoading={isLoading}
				onFilterOptionChange={setActiveOptions}
				filterLocation={CardComponentFilterLocation.RIGHT}
			/>
		)
	}, [isLoading, data, filters, headings, isMWM])

	return view
}
