import { ImportView } from "components/ImportComponents/ImportView"
import { Template } from "components/ImportComponents/types"
import { Page } from "components/Page"
import React, { useCallback, useMemo } from "react"
import { useTrans } from "translations"
import { trpc } from "Utils/trpc"
import { IImportContext } from "components/ImportComponents/ImportContext"
import { useNavigate } from "react-router-dom"
import { getTemplates } from "./templates"
import { addToSystem } from "./addToSystem"
import useCustomerParents from "api/hooks/useCustomerParents"
import { useCommonEntitiesStore } from "States/commonEntities"
import useCustomers from "api/hooks/useCustomers"
import { useConfig } from "api/hooks/useConfig"
import { IMPORT_STEPS } from "./importSteps"
import { IdentityKey } from "admin-client-server/src/utils/identitiesUtils"
import { useTerminalsState } from "States/Terminals"
import { useAccessParentsWithPoints } from "pages/infrastructure/manage/useAccessParentsWithPoints"

export const ImportTenants: React.FC = () => {
	const trpcUtils = trpc.useUtils()
	const { t } = useTrans()
	const { data: chains } = useCustomerParents()
	const { tenantCategories } = useCommonEntitiesStore()
	const navigate = useNavigate()
	const { accessParents } = useAccessParentsWithPoints()
	const { refetchCustomers } = useCustomers()
	const { currentTerminal } = useTerminalsState()
	const { isMWM } = useConfig()

	const { data: allAccessParents } = trpc.accessParents.getAll.useQuery({
		terminalId: isMWM ? undefined : currentTerminal?.id,
	})

	const { mutateAsync: createIdentities } = trpc.identities.create.useMutation()

	const breadcrumbs = useMemo(
		() => [{ url: "/customer/manage", name: t("sidebarLabels:manageCustomers") }],
		[t]
	)

	const checkDuplicates = useCallback(
		async function (type: IdentityKey, allValues: string[]) {
			const values = Array.from(new Set(allValues.filter(Boolean))).map(val => val.toString())

			const duplicates = await trpcUtils.client.identities.getDuplicateValues.query({
				type,
				values,
			})

			return duplicates?.reduce((acc: { [k: string]: string }, val: string) => {
				acc[val] = "importLabels:identityAlreadyExists"
				return acc
			}, {})
		},
		[trpcUtils]
	)

	const templates: Template[] = useMemo(
		() =>
			getTemplates({
				chains,
				tenantCategories: tenantCategories || [],
				checkDuplicates,
				isMWM,
				allAccessParents,
			}),
		[chains, tenantCategories, isMWM, allAccessParents, checkDuplicates]
	)

	const addToSystemFunction = useCallback(
		(context: IImportContext) =>
			addToSystem({
				context,
				createIdentities,
				accessParentIds: accessParents.map(el => el.id),
				tenantCategories,
				refetchCustomers,
				isMWM,
			}),
		[createIdentities, accessParents, refetchCustomers, tenantCategories, isMWM]
	)

	const onCancel = useCallback(() => {
		navigate("/customer/manage")
	}, [navigate])

	return (
		<Page title="importLabels:importCustomers" fullHeight breadcrumbsPaths={breadcrumbs}>
			<ImportView
				steps={IMPORT_STEPS}
				templates={templates}
				onCancel={onCancel}
				addToSystem={addToSystemFunction}
			/>
		</Page>
	)
}
