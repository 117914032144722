import TrashIcon from "Icons/Trash"
import classNames from "classnames"
import { CellWithAutocomplete } from "./CellWithAutocomplete"
import { useMemo } from "react"
import { Input } from "components/GenericComponents/input"
import { AccessTokenColumn, AccessTokenRow, FieldType } from "./types"
import { IdentityKey } from "admin-client-server/src/utils/identitiesUtils"

type Props = {
	columns: AccessTokenColumn[]
	emptyMessage: string
	data: AccessTokenRow[]
	getAutocompleteOptions: (key: IdentityKey, value: string) => Promise<any>
	onAutocompleteOptionSelected: (rowId: string, option: any) => void
	editCell: (
		rowId: string,
		isNewRow: boolean,
		columnKey: FieldType,
		value: string,
		error?: string
	) => void
	setCellError: (isNewRow: boolean, rowId: string, columnKey: FieldType, error: string) => void
	removeRow: (row: AccessTokenRow) => void
}

export const TableWithAutocomplete: React.FC<Props> = ({
	columns,
	emptyMessage,
	data,
	getAutocompleteOptions,
	onAutocompleteOptionSelected,
	editCell,
	setCellError,
	removeRow,
}) => {
	const rows = useMemo(
		() =>
			data?.map((row: AccessTokenRow, rowIndex) => (
				<div key={row.id} className="flex w-full">
					{columns.map(column => (
						<div
							key={`${row.id}-${column.name}`}
							className="border-t border-grey3 py-3 pr-3"
							style={{ width: `${95 / columns.length}%` }}
						>
							{column.isDropdown ? (
								<Input
									className="m-1"
									data={column.options || []}
									dropdownStyle={{ maxHeight: 190 }}
									dropdownIcon
									initialValue={row[column.name].value || ""}
									onChange={option => editCell(row.id, !!row.isNew, column.name, option.value)}
									ignoreOnRenderChange
								/>
							) : (
								<CellWithAutocomplete
									column={column}
									cell={row[column.name]}
									rowId={row.id}
									isNewRow={!!row.isNew}
									columnId={column.name}
									cellIndex={rowIndex}
									editCell={editCell}
									setCellError={setCellError}
									getAutocompleteOptions={getAutocompleteOptions}
									onAutocompleteOptionSelected={onAutocompleteOptionSelected}
									disabled={!!column.canBeDisabled && !row.isNew}
								/>
							)}
						</div>
					))}
					<div className="border-t border-gray pt-5" style={{ width: "5%" }}>
						<button onClick={() => removeRow(row)} className="ml-2 h-8" type="button">
							<TrashIcon />
						</button>
					</div>
				</div>
			)),
		[
			data,
			columns,
			getAutocompleteOptions,
			editCell,
			removeRow,
			onAutocompleteOptionSelected,
			setCellError,
		]
	)

	return (
		<>
			<div className="w-full table-auto border-separate border-spacing-y-3 min-h-[150px] overflow-y-scroll max-h-[400px]">
				<div className="flex sticky top-0 z-10 bg-white p-2.5">
					{columns.map(({ label, required }, colIndex) => (
						<div key={colIndex} style={{ width: `${95 / columns.length}%` }}>
							<span
								className={classNames(
									colIndex === 0 ? "mr-1" : "m-1",
									"font-dmSans text-sm font-medium text-black empty:hidden"
								)}
							>
								{label} {required && "*"}
							</span>
						</div>
					))}
					<div style={{ width: "5%" }}></div>
				</div>
				<div>
					{!data?.length && emptyMessage && (
						<div>
							<div className="border-t border-grey3">
								<p className="text-center bg-grey1 py-8 text-sm">{emptyMessage}</p>
							</div>
						</div>
					)}
					{rows}
				</div>
			</div>
		</>
	)
}
