import { useQuery } from "@tanstack/react-query"
import { getTokenFromCookie } from "Utils"
import { fetchRequest, request } from "../request"
import { RequestOptionsWithInit } from "../types"
import { getDatawarehousePayload, parseDatawarehouseInput } from "./payloads"
import { DataWareHouseInput, DataWareHouseInputGuard, EndpointResponseGuard } from "./types"

/** Http request against datawarehouse */
export const requestDataWareHouse = <T extends DataWareHouseInput>(
	input: DataWareHouseInputGuard<T>,
	options?: RequestOptionsWithInit<any>
) => {
	type Response = EndpointResponseGuard<typeof input>
	const optionsPayload = {
		...options,
		init: {
			...options?.init,
			headers: {
				...options?.init?.headers,
				authorization: `Bearer ${getTokenFromCookie()}`,
			},
		},
	}
	const parsedInput = parseDatawarehouseInput(input)
	const { cacheKey, url } = getDatawarehousePayload(parsedInput)

	return request<Response>(cacheKey, url, optionsPayload)
}

/** Hook for http request against datawarehouse */
export const useDataWareHouse = <T extends DataWareHouseInput>(
	input: DataWareHouseInputGuard<T>,
	options?: Parameters<typeof useQuery<any>>["2"]
) => {
	type Response = EndpointResponseGuard<typeof input>
	const headers = {
		headers: {
			authorization: `Bearer ${getTokenFromCookie()}`,
		},
	}

	const parsedInput = parseDatawarehouseInput(input)
	const { url, cacheKey } = getDatawarehousePayload(parsedInput)

	const datawarehouseResponse = useQuery<Response>(
		cacheKey,
		() => fetchRequest<Response>(url, headers),
		options
	)

	return datawarehouseResponse
}
