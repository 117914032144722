import { FC, useEffect, useMemo } from "react"
import { useFieldArray, UseFormReturn, useWatch } from "react-hook-form"
import { Translate, useTrans } from "translations"
import { CustomerAccessParent, CustomerWithAccessParentsAndChain } from "../types"
import classNames from "classnames"
import { useConfig } from "api/hooks/useConfig"
import { FormColumn, FormFieldType, GridForm } from "components/GridForm"
import { useModal } from "Contexts"
import { LoadingIndicator } from "Icons/loadingIndicator"
import { trpc } from "Utils/trpc"
import { useTerminalsState } from "States/Terminals"
import { sortBy } from "lodash"

interface CustomerAccessParentsFormSectionProps {
	formHandler: UseFormReturn<CustomerWithAccessParentsAndChain>
	containerRef?: React.RefObject<HTMLDivElement | null>
	useNumberForHint?: boolean
	setIsLoading?: (isLoading: boolean) => void
	isEdit?: boolean
}

type AccessParents = {
	id: string
}

export const CustomerAccessParentsFormSection: FC<CustomerAccessParentsFormSectionProps> = ({
	formHandler,
	useNumberForHint = true,
	setIsLoading,
	isEdit,
}) => {
	const { t } = useTrans()
	const {
		isMWM,
		config: { useRedundancyPoints },
	} = useConfig()
	const { ref: modalRef } = useModal()
	const { control, getValues, setValue } = formHandler
	const { fields: accessParentFields } = useFieldArray({ control, name: "accessParents" })
	const { currentTerminal } = useTerminalsState()
	const { data, isLoading, refetch } = trpc.accessParents.getAll.useQuery({
		terminalId: isMWM ? undefined : currentTerminal?.id,
		withRedundancyPoints: useRedundancyPoints,
	})

	useWatch({ control, name: "accessParents" })

	const accessParents = useMemo(() => sortBy(data ?? [], "name"), [data])

	useEffect(() => {
		refetch()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => setIsLoading?.(isLoading), [isLoading, setIsLoading])

	const accessParentOptions = useMemo(
		() =>
			accessParents?.map(({ name, id }) => ({
				label: name,
				value: id ?? "",
			})),
		[accessParents]
	)

	const columns: FormColumn[] = useMemo(
		() =>
			[
				{
					label: t("actions:chooseAccessParent"),
					name: "id",
					required: true,
					unique: true,
					fieldType: FormFieldType.SELECT,
					options: accessParentOptions,
					enabled: true,
					placeholder: t("actions:chooseAccessParent"),
					onSelectChange: (value, name) => {
						const index = Number(name.split(".")[1])
						const accessParent = accessParents?.find(s => s.id === value.value)!
						setValue(`accessParents.${index}.redundancyPoints`, accessParent.redundancyPoints)
					},
				} as FormColumn,
				useRedundancyPoints &&
					({
						name: "redundancyPoints",
						label: t("entities:redundancyPoints"),
						headerTooltipComponent: (
							<Translate
								i18nKey="hints:redundancyPointDescription"
								components={[
									// Anchor content is added by the translation
									// eslint-disable-next-line jsx-a11y/anchor-has-content
									<a href="mailto:support@carrot.tech" className="underline text-white" />,
								]}
							/>
						),
						fieldType: FormFieldType.READ_ONLY,
						readOnlyContent: rowIndex => {
							const accessParent = getValues("accessParents")[rowIndex]
							return <ReadOnlyRedundancyPoints accessParent={accessParent} />
						},
					} as FormColumn),
			].filter(Boolean) as FormColumn[],
		[t, accessParentOptions, useRedundancyPoints, accessParents, setValue, getValues]
	)

	const canDeleteRow = accessParentFields.length > 1 || isMWM

	if (isLoading) {
		return (
			<div className="min-w-[200px] h-[150px] flex flex-col items-center gap-4 justify-center">
				<LoadingIndicator />
				{t("systemMessages:loadingStations") + "..."}
			</div>
		)
	}

	return (
		<div className="md:ml-3 mb-8">
			<div className={classNames(isEdit && `bg-grey1 p-5 mb-4`)}>
				<h2 className="mb-2 font-dmSans text-base font-medium">{`${
					useNumberForHint ? "2. " : ""
				}${t("hints:tenantRoomAccess")}`}</h2>
				<p className={classNames("font-dmSans text-sm font-normal text-black", !isEdit && "mb-4")}>
					<Translate
						i18nKey={"hints:tenantRoomAccessDetails"}
						values={{
							link: t("sidebarLabels:manageInfrastructure"),
						}}
						components={[
							<a
								href="/infrastructure/manage"
								style={{ textDecoration: "underline" }}
								target="_blank"
								rel="noreferrer"
							>
								${t("sidebarLabels:manageInfrastructure")}
							</a>,
						]}
					/>
				</p>
			</div>
			<GridForm<AccessParents[]>
				formHandler={formHandler}
				columns={columns}
				enableDeleteColumn={canDeleteRow}
				enableAddRowButton={accessParentFields.length < (accessParents?.length ?? 0)}
				selectInputMenuPortalTarget={modalRef.current}
				withColSpacing
			/>
		</div>
	)
}

export const ReadOnlyRedundancyPoints: FC<{
	accessParent?: CustomerAccessParent
}> = ({ accessParent }) => {
	const { t } = useTrans()
	const { redundancyPoints = [] } = accessParent ?? {}

	if (!accessParent?.id) {
		return null
	}

	return (
		<ul className="list-disc text-sm">
			{!redundancyPoints?.length ? (
				<li>{t("hints:noRedundancyPoints")}</li>
			) : (
				redundancyPoints?.map(({ id, name }) => <li key={id}>{name}</li>)
			)}
		</ul>
	)
}
