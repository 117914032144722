import { Popover } from "@headlessui/react"
import ArrowDown from "Icons/ArrowDown"
import classNames from "classnames"
import { useMemo } from "react"
import { useTrans } from "translations"
import { FilterSortMenu } from "./FilterSortMenu"
import { useFilterSort } from "./FilterSortContext"
import { FilterSort } from "./types"
import { Card, CardComponentFilterLocation } from "../card"
import { usePdfPreview } from "pages/home/pdfPreview"

type FilterSortButtonProps = FilterSort & Pick<React.ComponentProps<typeof Card>, "filterLocation">

export const FilterSortButton: React.FC<FilterSortButtonProps> = props => {
	const { t } = useTrans()
	const { activeOptions } = useFilterSort()
	const { pdfPreviewOpen } = usePdfPreview()

	const activeOptionsForFilterSort = useMemo(
		() => activeOptions[props.id] || [],
		[activeOptions, props.id]
	)

	const optionsToRender = useMemo(
		() =>
			(activeOptionsForFilterSort.filter(value => !!value.option).length
				? activeOptionsForFilterSort
				: props.options.filter(({ value }) => value === props.defaultValue || value === "")
			)
				.map(({ option, translate }) => {
					if (option === "interval.custom" || !option) return t("genericLabels:custom")

					return translate ? t(option) : option
				})
				.join(", "),
		[activeOptionsForFilterSort, props.defaultValue, props.options, t]
	)

	return (
		<Popover className="relative">
			{({ open, close }) => (
				<>
					<Popover.Button
						className={classNames(
							"max-w-[200px] flex items-center gap-2 text-lg bg-grey1 py-1 px-2 h-8 hover:bg-grey2 mb-2",
							open && "bg-grey2"
						)}
					>
						{optionsToRender ? (
							<div className="text-sm text-ellipsis whitespace-nowrap">{optionsToRender}</div>
						) : (
							<div className="text-sm font-medium italic">{t("filterLabels:selectFilter")}</div>
						)}
						{!pdfPreviewOpen && (
							<ArrowDown
								className={classNames(
									"overflow-visible",
									open && "rotate-180 transition-transform"
								)}
							/>
						)}
					</Popover.Button>
					<Popover.Panel
						className={classNames(
							"absolute z-20",
							props.filterLocation === CardComponentFilterLocation.RIGHT && "right-0"
						)}
					>
						<FilterSortMenu
							close={close}
							filterSorts={[{ ...props }]}
							initialFilterSortOpenId={props.id}
						/>
					</Popover.Panel>
				</>
			)}
		</Popover>
	)
}
