import { IInputOption, Input } from "components/GenericComponents/input"
import React, { useCallback, useContext, useMemo } from "react"
import { useTrans } from "translations"
import { ImportContext } from "../../ImportContext"
import { UploadZone } from "./UploadZone"

export const UploadFile = () => {
	const { t } = useTrans()
	const {
		templates,
		selectedTemplate,
		setSelectedTemplate,
		selectedParentIds,
		setSelectedParentIds,
		reset,
	} = useContext(ImportContext)

	const templateOptions = useMemo(
		() =>
			templates.map(template => ({
				option: t(template.title),
				value: template.key,
			})),
		[templates, t]
	)

	const onTemplateChange = useCallback(
		(option: IInputOption) => {
			const selected = templates.find(t => t.key === option.value)

			const canBeUsed = selected?.checkCanBeUsed()

			if (canBeUsed) {
				setSelectedTemplate(selected)
				setSelectedParentIds({})
				reset()
			} else {
				selected?.showCantBeUsed?.()
			}
		},
		[templates, setSelectedTemplate, setSelectedParentIds, reset]
	)

	const parentSelections = useMemo(() => selectedTemplate?.parentSelections, [selectedTemplate])

	const updateSelectedParentIds = useCallback(
		(key: string, value: string) => {
			const newValues = {
				...selectedParentIds,
				[key]: value,
			}

			const dependents = parentSelections?.filter(p => p.dependsOn === key)
			if (dependents?.length) {
				dependents.forEach(d => {
					newValues[d.key] = ""
				})
			}

			setSelectedParentIds(newValues)
		},
		[selectedParentIds, setSelectedParentIds, parentSelections]
	)

	const showUpload = useMemo(() => {
		const hasParentSelectionValues = parentSelections?.every(ps => selectedParentIds[ps.key])

		return !!selectedTemplate && (!parentSelections || hasParentSelectionValues)
	}, [selectedTemplate, parentSelections, selectedParentIds])

	const parentSelectionsView = useMemo(
		() =>
			parentSelections?.map((selection, i: number) => {
				const chosenPreviousOption = selectedParentIds[selection.dependsOn!]
				if (i > 0 && selection.dependsOn && !chosenPreviousOption) {
					return null
				}

				const chosenOption = selectedParentIds[selection.key]
				const options = selection.getOptions(chosenPreviousOption)

				if (!options.length && selection.emptyComponent) {
					return <div key={selection.key}>{selection.emptyComponent}</div>
				}

				return (
					<div key={selection.key}>
						<div className="text black font-dmSans text-sm font-medium">{t(selection.label)} *</div>
						<div className="mb-6">
							<Input
								placeholder={t(selection.placeholder)}
								data={options}
								dropdownStyle={{ maxHeight: 190 }}
								dropdownIcon
								onChange={option => updateSelectedParentIds(selection.key, option.value)}
								ignoreOnRenderChange
								initialValue={chosenOption}
								noInitialOption={!chosenOption}
							/>
						</div>
					</div>
				)
			}),
		[parentSelections, selectedParentIds, t, updateSelectedParentIds]
	)

	return (
		<div className="w-full">
			<div className="flex flex-row gap-11">
				<div className="w-1/2">
					<div className="text black font-dmSans text-sm font-medium">
						{t("importLabels:chooseTemplateLabel")} *
					</div>
					<div className="mb-6">
						<Input
							placeholder={t("importLabels:selectTemplate")}
							data={templateOptions}
							dropdownStyle={{ maxHeight: 190 }}
							dropdownIcon
							initialValue={selectedTemplate?.key}
							onChange={onTemplateChange}
							ignoreOnRenderChange
							noInitialOption={!selectedTemplate}
						/>
					</div>

					{parentSelectionsView}
				</div>

				<div className="w-1/2 pr-16 pt-5">{showUpload && <UploadZone />}</div>
			</div>
		</div>
	)
}
