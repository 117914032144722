import { isCarrotAdmin } from "Utils"
import { AddButton } from "components/AddButton"
import { TerminalDrawer } from "pages/infrastructure/terminals/TerminalDrawer"
import { Terminal } from "States/Terminals"
import { MultiListSectionConfig } from "components/pageCards/multiListTable/multiListSection"
import { FilterSort } from "components/pageCards/filterSort/types"
import { CardComponentFilterLocation } from "components/pageCards/card"
import { ActiveOptions } from "components/pageCards/filterSort/FilterSortContext"

type Props = {
	isMWM: boolean
	createTerminal: () => void
	terminalList: Terminal[]
	isLoadingTerminals: boolean
	terminalId: string
	onRowClick: (terminal: Terminal) => void
	filters: FilterSort[]
	setTerminalsFilterState: (activeOptions: ActiveOptions<any>) => void
}

export default function getTerminalSection({
	isMWM,
	createTerminal,
	terminalList,
	isLoadingTerminals,
	terminalId,
	onRowClick,
	filters,
	setTerminalsFilterState,
}: Props): MultiListSectionConfig<Terminal[]> | null {
	return isCarrotAdmin() || isMWM
		? {
				type: "list",
				title: "entities:terminals",
				customButton: <AddButton onClick={createTerminal} />,
				data: terminalList,
				isLoading: isLoadingTerminals,
				getInitiallySelectedRows: entries => entries?.filter(({ id }) => id === terminalId),
				entryRender: entry => ({
					labelTop: entry.name,
				}),
				onRowClick,
				drawer: {
					content: <TerminalDrawer terminalId={terminalId} />,
				},
				emptyViewType: "noTerminals",
				labelSingle: "entities:terminal",
				labelMultiple: "entities:terminals",
				filters,
				filterLocation: CardComponentFilterLocation.INSIDE,
				onFilterOptionChange: setTerminalsFilterState,
		  }
		: null
}
