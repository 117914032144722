import classNames from "classnames"
import moment from "moment"
import React, { useCallback, useMemo, useState } from "react"
import { useTrans } from "translations"
import DatePicker from "../DatePicker"
import { ActiveOptions, useFilterSort } from "./FilterSortContext"
import { FilterSort, Option } from "./types"
import { TimestampISO, TimeFilterCustomDate } from "./filterTimeFrame"

const getCustomTimeFrameOption = (value: TimeFilterCustomDate, option?: string) => ({
	option: option || "interval.custom",
	value,
	translate: true,
})

/** Either returns the active custom interval or the initial interval from the filter/sort */
const getInitialCustomInterval = (
	activeOptions: ActiveOptions<any>,
	initialStartDate: Date,
	initialEndDate: Date
) => {
	const hasActiveCustomTimeFrame = !!activeOptions?.["timeframe"]?.[0].value.match(/Z&/g)?.length

	if (hasActiveCustomTimeFrame) {
		const [startTimeISO, endTimeISO] = activeOptions["timeframe"][0].value.split("&") as [
			TimestampISO,
			TimestampISO,
		]
		return { startDate: new Date(startTimeISO), endDate: new Date(endTimeISO) }
	}
	return { startDate: initialStartDate, endDate: initialEndDate }
}

type FilterTimeFrameProps = { filterSort: FilterSort; close: () => void }

export const FilterSortTimeFrame: React.FC<FilterTimeFrameProps> = ({ filterSort, close }) => {
	const { t } = useTrans()
	const { activeOptions, onOptionSelect } = useFilterSort()
	const { options, initialStartDate = new Date(), initialEndDate = new Date() } = filterSort

	const [customInterval, setCustomInterval] = useState<{ startDate: Date; endDate: Date }>(
		getInitialCustomInterval(activeOptions, initialStartDate, initialEndDate)
	)

	const onDateChangeHandler = useCallback(
		(type: "start" | "end") => (date?: Date) => {
			const newStartDate = moment(type === "start" ? date : customInterval.startDate)
			const newEndDate = moment(type === "start" ? customInterval.endDate : date)
				.endOf("day")
				.toDate()
			const value: TimeFilterCustomDate = `${newStartDate.toISOString() as TimestampISO}&${
				newEndDate.toISOString() as TimestampISO
			}`
			setCustomInterval({ startDate: newStartDate.toDate(), endDate: newEndDate })

			onOptionSelect(filterSort, getCustomTimeFrameOption(value) as Option)
		},
		[customInterval.endDate, customInterval.startDate, filterSort, onOptionSelect]
	)

	const onPresetClick = useCallback(
		(option: Option) => {
			onOptionSelect(filterSort, option)
			close()
		},
		[close, filterSort, onOptionSelect]
	)

	const isOptionActive = useCallback(
		(value: string) => activeOptions[filterSort.id]?.some(option => option.value === value),
		[activeOptions, filterSort.id]
	)

	const presetOptionGroups = useMemo(() => [options.slice(0, 4), options.slice(4)], [options])

	return (
		<div className="self-center">
			<div className="w-[440px] gap-px overflow-visible bg-white">
				<div className="grid grid-cols-2 gap-4">
					<DatePicker
						onChange={onDateChangeHandler("start")}
						value={customInterval.startDate}
						heading={t("filterLabels:startDate")}
					/>
					<DatePicker
						onChange={onDateChangeHandler("end")}
						value={customInterval.endDate}
						heading={t("filterLabels:endDate")}
						minDate={customInterval.startDate}
					/>
				</div>
				<p className="my-6 text-center font-dmSans text-sm text-grey6">
					{t("hints:selectIntervalPreset")}
				</p>
				<div className="grid grid-cols-2 gap-4">
					{presetOptionGroups.map((options, index) => (
						<FilterSortTimeFramePresetOptions
							key={index}
							options={options}
							isOptionActive={isOptionActive}
							onPresetClick={onPresetClick}
						/>
					))}
				</div>
			</div>
		</div>
	)
}

type FilterSortTimeFramePresetOptionsProps = {
	options: Option[]
	isOptionActive: (value: string) => boolean
	onPresetClick: (option: Option) => void
}

const FilterSortTimeFramePresetOptions: React.FC<FilterSortTimeFramePresetOptionsProps> = ({
	options,
	isOptionActive,
	onPresetClick,
}) => {
	const { t } = useTrans()

	return (
		<ul className="divide-y">
			{options.map((option, index) => (
				<li
					key={option.value + index}
					className={classNames(
						"flex h-10 items-center",
						"cursor-pointer hover:bg-grey1",
						isOptionActive(option.value) && "bg-grey1"
					)}
				>
					<button
						onClick={() => onPresetClick(option)}
						className="w-full h-full text-left px-3 font-dmSans text-sm text-black"
					>
						{option.translate ? t(option.option) : option.option}
					</button>
				</li>
			))}
		</ul>
	)
}
