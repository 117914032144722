import { CardFilterTimeFrameIso } from "components/pageCards/filterSort/filterTimeFrame"
import { NumberCard } from "components/pageCards/numberCard"
import { useState } from "react"
import { useTerminalsState } from "States/Terminals"
import { useTrans } from "translations"
import { useDataWareHouse } from "Utils/api/datawarehouse/request"
import { initEndTimeIso, initStartTimeIso } from "../functions/variables"

export const TenantNumberCard = () => {
	const { t } = useTrans()
	const [{ startTimeISO, endTimeISO }] = useState<CardFilterTimeFrameIso>({
		startTimeISO: initStartTimeIso,
		endTimeISO: initEndTimeIso,
	})

	const { currentTerminal } = useTerminalsState()

	const { data: currentPeriod, isLoading } = useDataWareHouse({
		endpoint: "terminal/customers",
		pathParams: { id: currentTerminal.id },
		filters: { startTimeISO, endTimeISO },
	})

	const registeredTenants = currentPeriod?.customers.length
	const activeTenants = currentPeriod?.customers.filter(
		el => (el.totalWasteWeight.quantity ?? 0) !== 0
	).length

	const numbers = [
		{ heading: t("statisticsLabels:registeredTenants"), data: registeredTenants ?? "-" },
		{ heading: t("statisticsLabels:activeThisMonth"), data: activeTenants ?? "-" },
	]

	return <NumberCard title="entities:customers" numbers={numbers} isLoading={isLoading} />
}
