import { BarChart } from "components/BarChart"
import { getTimeFrame } from "components/pageCards/filterSort/filterTimeFrame"
import { orderBy, truncate } from "lodash"
import { useEffect, useMemo, useState } from "react"
import { useCommonEntitiesStore } from "States/commonEntities"
import { useTerminalsState } from "States/Terminals"
import { useTrans } from "translations"
import { useDataWareHouse } from "Utils/api/datawarehouse/request"
import { Terminal, TerminalAggregate } from "Utils/api/datawarehouse/responseTypes"
import { CategoryNameFilters } from "Utils/api/datawarehouse/types"
import { FetchedWasteTypes } from "Utils/api/sanity/types"
import { ComparisonWasteTypeDataInterface } from "../overview.interface"
import { LoadingIndicator } from "Icons/loadingIndicator"
import { EmptyView } from "components/EmptyView/EmptyView"
import { ActiveOptions } from "components/pageCards/filterSort/FilterSortContext"
import { FilterSort } from "components/pageCards/filterSort/types"
import { Card, CardComponentFilterLocation } from "components/pageCards/card"
import {
	FilterTimeFrameValue,
	createTimeFilters,
} from "components/pageCards/filterSort/filterCreators"
import { groupWasteTypes } from "./WasteTypesInUse/functions"
import { WasteTypeConfig, useWasteTypeConfig } from "pages/configuration/useWasteTypeConfig"
import { COLORS, getNewColor } from "pages/customer/dashboard/functions/pieChartData"
import { useConfig } from "api/hooks/useConfig"
import { useHomeLoadingState } from "pages/home/loadingState"

const createBarChartData = (
	realEstate: Terminal | undefined,
	aggregatedRealEstate: TerminalAggregate | undefined,
	wasteTypes: FetchedWasteTypes | undefined,
	translationTextUnknown: string,
	translationTextOther: string,
	wasteTypeConfig: WasteTypeConfig[],
	otherColor: string
): ComparisonWasteTypeDataInterface[] => {
	if (!realEstate || !aggregatedRealEstate) return []

	const aggregateWasteTypesNotInCurrent =
		aggregatedRealEstate.wasteTypes?.filter(
			wt => !realEstate.wasteTypes?.some(el => el.code === wt.code)
		) ?? []

	const combinedAggregateShareUnknownWasteTypes = aggregateWasteTypesNotInCurrent.reduce(
		(sum, wt) => wt.share + sum,
		0
	)

	const groupWasteTypesData = groupWasteTypes(realEstate.wasteTypes ?? [], wasteTypes ?? [])
	const groupedAggregatedWasteTypesData = groupWasteTypes(
		aggregatedRealEstate.wasteTypes ?? [],
		wasteTypes ?? []
	)

	let curColors = [...COLORS]
	const barChartData: ComparisonWasteTypeDataInterface[] =
		groupWasteTypesData?.reduce(
			(acc: ComparisonWasteTypeDataInterface[], wasteType) => {
				const defaultAggregate = { weight: { value: 0, unit: "kg" }, share: 0 }
				const aggregateWasteType =
					groupedAggregatedWasteTypesData.find(wt => wt.id === wasteType.id) ?? defaultAggregate

				if (wasteType.share < 0.03) {
					const [other, ...rest] = acc
					return [
						{
							...other,
							average: other.average + aggregateWasteType.share * 100,
							value: other.value + wasteType.share * 100,
						},
						...rest,
					]
				}

				const customWasteType = wasteTypeConfig.find(wtc => wtc.wasteTypeCode === wasteType.code)

				const [color, newColors] = getNewColor(curColors)
				curColors = newColors
				const mappedObj: ComparisonWasteTypeDataInterface = {
					average: aggregateWasteType.share * 100,
					value: wasteType.share * 100,
					name: customWasteType?.name || wasteType.name,
					color: customWasteType?.color || color || "",
				}
				return [...acc, mappedObj]
			},
			[
				{
					name: translationTextOther,
					average: combinedAggregateShareUnknownWasteTypes * 100,
					value: 0,
					color: otherColor,
				},
			]
		) || []

	// On a typical screen truncating the names to 100 / columns will avoid overlapping text
	const charactersToShow = 100 / barChartData.length

	const truncatedBarChartData: ComparisonWasteTypeDataInterface[] = barChartData.map(el => ({
		...el,
		fullName: el.name,
		name: truncate(el.name, { length: charactersToShow }),
	}))

	return orderBy(truncatedBarChartData, "name", "asc").filter(el => el.percent !== 0)
}

const defaultPeriod = FilterTimeFrameValue.LAST_30_DAYS

export const ComparisonWasteTypeDistribution = () => {
	const [{ period: [selectedPeriod] = [] }, setActiveOptions] = useState<ActiveOptions<"period">>(
		{} as any
	)
	const { wasteTypes } = useCommonEntitiesStore()
	const { t } = useTrans()
	const filters: FilterSort[] = [createTimeFilters({ defaultValue: defaultPeriod })]

	const { config } = useConfig()
	const { setComparisonDistributionLoading } = useHomeLoadingState()

	const { currentTerminal } = useTerminalsState()
	const { wasteTypeConfig, terminalColors } = useWasteTypeConfig()

	const periodFilterValue = useMemo(
		() => getTimeFrame((selectedPeriod?.value as FilterTimeFrameValue) ?? defaultPeriod),
		[selectedPeriod]
	)

	const {
		data,
		isError: isTerminalError,
		isLoading,
	} = useDataWareHouse({
		endpoint: "terminal",
		terminalId: currentTerminal.id,
		filters: periodFilterValue,
	})

	const {
		data: aggregatedData,
		isError: isAggregatesError,
		isLoading: isAggregatesLoading,
	} = useDataWareHouse({
		endpoint: "terminal-aggregates",
		filters: {
			...periodFilterValue,
			commercialTerminalCategory:
				currentTerminal.commercialRealEstateCategory as CategoryNameFilters,
		},
	})

	useEffect(() => {
		setComparisonDistributionLoading(isLoading || isAggregatesLoading)
	}, [setComparisonDistributionLoading, isLoading, isAggregatesLoading])

	const isError = isTerminalError || isAggregatesError
	const realEstate = data?.terminal
	const wasteTypeAverages = aggregatedData?.terminalAggregates

	const colors = useMemo(
		() => ({
			value: terminalColors?.primaryColor || config?.primaryColor || "#72723B",
			average: terminalColors?.secondaryColor || config?.secondaryColor || "#E9D7C9",
		}),
		[config, terminalColors]
	)

	const otherColor = terminalColors?.otherColor || config.otherColor || "#B2ABAB"
	const barChartData = useMemo(
		() =>
			wasteTypes?.length
				? createBarChartData(
						realEstate,
						wasteTypeAverages,
						wasteTypes,
						t("common:unknown"),
						t("circularityLabels:otherWasteTypes"),
						wasteTypeConfig,
						otherColor
				  )
				: [],
		[t, wasteTypes, realEstate, wasteTypeAverages, wasteTypeConfig, otherColor]
	)

	const chart = useMemo(() => {
		if (isLoading || isAggregatesLoading) {
			return (
				<div className="flex justify-center items-center min-h-full h-full">
					<LoadingIndicator />
				</div>
			)
		}

		if (isError) {
			return <EmptyView type="failToFetch" className="min-h-[300px]" />
		}

		const isEmpty = barChartData.length < 2 // either nothing or "other waste types" only
		if (isEmpty) {
			return <EmptyView type="noDataForInterval" className="min-h-[300px]" />
		}

		return (
			<BarChart
				legendName={realEstate?.terminalName ?? ""}
				data={barChartData}
				unit=" %"
				showTickColors
				colors={colors}
			/>
		)
	}, [barChartData, isAggregatesLoading, isError, isLoading, realEstate, colors])

	return (
		<Card
			title={"circularityLabels:comparisonWasteTypeDist"}
			filters={filters}
			onFilterOptionChange={setActiveOptions}
			filterLocation={CardComponentFilterLocation.RIGHT}
		>
			{chart}
		</Card>
	)
}
