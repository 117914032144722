import classNames from "classnames"
import { FC, useEffect, useRef } from "react"
import { useTrans } from "translations"
import { match } from "ts-pattern"
import { Card, ICardComponentProps } from "../card"
import { DataTableCardProps } from "../dataTable/dataTableCard"
import { DataListCard, DataListCardProps } from "./DataListCard"
import { MultiListDrawerConfig } from "./multiListDrawer"
import { useMultiListState } from "./multiListState"
import { useMultiSelectContext } from "./multiSelectContext"
import { Pagination, TableWithSelect } from "components/TableWithSelect"
import { EmptyView } from "components/EmptyView/EmptyView"
import { SortingState } from "@tanstack/react-table"
import { isEmpty } from "lodash"
import { LinkWithListCard, LinkWithListCardProps } from "./LinkWithListCard"
import { Search, SearchProps } from "./search"

export type MultiListSectionConfig<TData extends Record<string, any>[] = Record<string, any>[]> =
	ICardComponentProps & {
		data: TData | undefined
		drawer?: MultiListDrawerConfig
		pagination?: Pagination
		updateSorting?: (sorting: SortingState) => void
		searchProps?: SearchProps
	} & (
			| ({
					type: "list"
			  } & DataListCardProps<TData>)
			| ({
					type: "table"
			  } & DataTableCardProps)
			| ({
					type: "linkWithList"
			  } & LinkWithListCardProps<TData>)
		)

export type MultiListSectionProps = {
	state: ReturnType<typeof useMultiListState>
	index: number
} & MultiListSectionConfig

export const MultiListSection: FC<MultiListSectionProps> = props => {
	const { state, title = "", index, searchProps } = props
	const { t } = useTrans()
	const containerRef = useRef(null)

	const {
		sections,
		onSectionClick,
		setSection,
		onSectionEntryClick,
		onLinkClick,
		onTableRowClick,
		setDrawer,
	} = state
	const { selectedIds, setSelectedIds } = useMultiSelectContext()
	const { isOpen } = sections?.[index] || {}

	const onTitleClick = () => onSectionClick(index)

	useEffect(() => {
		setSection(index, { ref: containerRef })
	}, [containerRef, index, setSection])

	if (!isOpen)
		return (
			<div
				className="cursor-pointer flex-col justify-between bg-white"
				onClick={() => onSectionClick(index)}
			>
				<div
					className={classNames(
						"my-1 w-full border-b-2 border-grey1 py-5 px-2 text-2xl",
						"sm:px-5"
					)}
				>
					<h2 className="w-8 [writing-mode:vertical-rl] [text-orientation:mixed]">{t(title)}</h2>
				</div>
				<div className="h-16 border-t-2 border-grey1"></div>
			</div>
		)

	return match(props)
		.with({ type: "list" }, props => {
			const onSelect: (typeof props)["onRowClick"] = (entry, isMultiSelect) => {
				props.onRowClick?.(entry, isMultiSelect)
				if (!isMultiSelect) onSectionEntryClick(index)
			}
			return <DataListCard {...{ ...props, containerRef, onTitleClick, onRowClick: onSelect }} />
		})
		.with({ type: "linkWithList" }, props => {
			const onSelect: (typeof props)["onRowClick"] = (entry, isMultiSelect) => {
				props.onRowClick?.(entry, isMultiSelect)
				if (!isMultiSelect) onSectionEntryClick(index)
			}
			const onLinkButtonClick: (typeof props)["onLinkButtonClick"] = () => {
				props.onLinkButtonClick?.()
				onLinkClick(index)
			}
			return (
				<LinkWithListCard
					{...{ ...props, containerRef, onTitleClick, onRowClick: onSelect, onLinkButtonClick }}
				/>
			)
		})
		.with({ type: "table" }, props => {
			const onRowClick: (typeof props)["onRowClick"] = entry => {
				props.onRowClick?.(entry)

				if (selectedIds.includes(entry.id as string)) {
					onTableRowClick(index, selectedIds.filter(id => id !== entry.id)?.length)
				} else {
					onTableRowClick(index, selectedIds.length + 1)
				}
			}

			const onRowSelectionChange = (selection: Record<number, boolean>) => {
				setDrawer({ isOpen: !isEmpty(selection) })
			}

			const showEmpty = !props.isLoading && !props.isError && !props.data?.length
			return (
				<Card {...props} hasXContentPadding={false} hasYContentPadding={false} overflow="auto">
					{searchProps && <Search {...searchProps} />}
					{props.emptyViewType && showEmpty && <EmptyView type={props.emptyViewType} />}
					{!showEmpty && (
						<div className="h-[calc(100%-60px)]">
							<TableWithSelect
								defaultSortingHeader={props.defaultSortingHeader ?? { id: "name", desc: false }}
								{...{
									...props,
									onTitleClick,
									containerRef,
									onRowClick,
									selectedIds,
									setSelectedIds,
									onRowSelectionChange,
								}}
							/>
						</div>
					)}
				</Card>
			)
		})
		.exhaustive()
}
