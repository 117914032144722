import { Terminal } from "States/Terminals"
import { useGeneralConfig } from "./States/generalConfig"
import { IAuthJwt } from "./types"
import { monad } from "./Utils/fs"
import { sortAlphabeticallyByProperty } from "Utils/sorting"

export type IKeyValue = { key: string; value: string | null }
export type IKeyValueObj = { [key: string]: string | null }

export const removeQuotes = (str: string) => {
	return str.replace(/"/g, "")
}

export function getKey<T, K extends keyof T>(obj: T, key: K) {
	return obj[key]
}

export function isObjKey<T extends object>(key: any, obj: T): key is keyof T {
	return key in obj
}

// Empty string ("") if not finding jwt token cookie
export const getTokenFromCookie = () =>
	document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, "$1")

export const parseJwtToken = (token: string): IAuthJwt | null => {
	try {
		if (typeof token === "string") {
			return JSON.parse(atob(token.split(".")[1]))
		} else {
			console.error("Please make sure the token specified is a string")
			return null
		}
	} catch (e) {
		console.error("Could not parse the token. Please try again.")
		return null
	}
}

export const getJwtObj = () => {
	return monad(getTokenFromCookie()).map(token => (token ? parseJwtToken(token) : null))[0]
}

export type ICommonJwt = {
	exp: number
	iat: number
	iss: string
	permissions?: string[]
	sub: string
} | null

export const CARROT_ADMIN = "wiq-admin"
export const ADMIN = "adminclient-admin"

export const getCommonJwt = (): ICommonJwt =>
	[useGeneralConfig.getState()].map(({ authJwt }) => authJwt)[0]

export const hasPermissions = (permissions: string[]) =>
	permissions.every(p => getCommonJwt()?.permissions?.includes(p))

export const isCarrotAdmin = () =>
	getCommonJwt()?.iss === "waste-iq" || getCommonJwt()?.permissions?.includes(CARROT_ADMIN)

export const isAdmin = () => isCarrotAdmin() || getCommonJwt()?.permissions?.includes(ADMIN)

export const hasAnyTerminalAccess =
	isAdmin() || getCommonJwt()?.permissions?.find(p => p.includes("terminalList:"))

export const getPermittedTerminals = (terminals: Terminal[] = [], isMWM: boolean) => {
	const permittedList =
		isCarrotAdmin() || isMWM
			? terminals
			: terminals?.filter(el => hasPermissions([`terminalList:${el.accessToken}`]))

	const orderedList = permittedList.sort(sortAlphabeticallyByProperty)

	return orderedList
}
