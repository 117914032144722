import { useCommonEntitiesStore } from "States/commonEntities"
import React, { useCallback } from "react"
import { Translate, useTrans } from "translations"
import { ACCESS_POINT } from "admin-client-server/src/coreApi/models/Common"
import { WasteTypeData } from "./useWasteTypeConfig"
import { getWasteTypeName } from "Utils/commonExportFunctions"
import { filter } from "lodash"
import { Popover } from "@headlessui/react"
import { SketchPicker } from "react-color"
import { Button } from "components/button"
import InfoPopup from "components/InfoPopup/InfoPopup"

type Props = {
	containers: ACCESS_POINT[]
	data: WasteTypeData[]
	setHasUnsavedChanges: (hasChanges: boolean) => void
	onSave: () => void
	updateLoading: boolean
	updatedWasteTypes: WasteTypeData[]
	setUpdatedWasteTypes: React.Dispatch<React.SetStateAction<WasteTypeData[]>>
}

export const WasteStreamsTable: React.FC<Props> = ({
	containers,
	data,
	setHasUnsavedChanges,
	onSave,
	updateLoading,
	updatedWasteTypes,
	setUpdatedWasteTypes,
}) => {
	const { t } = useTrans()
	const { languages, wasteTypes: sanityWasteTypes } = useCommonEntitiesStore()

	const getConfig = useCallback(
		(code?: string) => {
			return (
				updatedWasteTypes.find(wasteType => wasteType.wasteTypeCode === code) ??
				data?.find(wasteType => wasteType.wasteTypeCode === code)
			)
		},
		[data, updatedWasteTypes]
	)

	const onDisplayNameChange = useCallback(
		(wasteTypeCode: string, displayName: string, locale: string) => {
			const existing = getConfig(wasteTypeCode)
			const color = existing?.color ?? ""
			const names = existing?.names ?? []

			const updatedConfig = {
				wasteTypeCode,
				color,
				names: filter(names, n => n.locale !== locale).concat({ displayName, locale }),
			}
			setUpdatedWasteTypes(prev => [
				...filter(prev, wt => wasteTypeCode !== wt.wasteTypeCode),
				updatedConfig,
			])
			setHasUnsavedChanges(true)
		},
		[setUpdatedWasteTypes, setHasUnsavedChanges, getConfig]
	)

	const onColorChange = useCallback(
		(wasteTypeCode: string, color: string) => {
			const names = getConfig(wasteTypeCode)?.names ?? []
			setUpdatedWasteTypes(prev => [
				...filter(prev, wt => wasteTypeCode !== wt.wasteTypeCode),
				{
					wasteTypeCode,
					color,
					names,
				},
			])
			setHasUnsavedChanges(true)
		},
		[setUpdatedWasteTypes, setHasUnsavedChanges, getConfig]
	)

	return (
		<table className="table-auto w-full text-sm">
			<thead>
				<tr className="border-b h-12 text-left">
					<th>
						<div className="mr-4">{t("configLabels:code")}</div>
					</th>
					<th>
						<div className="ml-2">{t("configLabels:name")}</div>
					</th>
					{languages?.map(language => (
						<th key={language.code}>
							<div className="ml-2 flex items-center">
								{t("configLabels:displayName")} {t("languages:" + language.code)}
								<InfoPopup
									id={`configLanguage-${language.code}`}
									size={15}
									className="ml-1 inline-block align-baseline"
									width="350px"
								>
									<Translate
										i18nKey={"hints:changeDisplayName"}
										values={{
											link: t("sidebarLabels:manageInfrastructure"),
										}}
										components={[
											<a
												href="/infrastructure/manage"
												style={{ textDecoration: "underline", color: "#FFF" }}
												target="_blank"
												rel="noreferrer"
											>
												${t("sidebarLabels:manageInfrastructure")}
											</a>,
										]}
									/>
								</InfoPopup>
							</div>
						</th>
					))}
					<th>
						<div className="ml-4 flex items-center">
							{t("configLabels:color")}
							<InfoPopup
								id="configColor"
								size={15}
								className="ml-1 inline-block align-baseline"
								width="350px"
							>
								{t("hints:changeColor")}
							</InfoPopup>
						</div>
					</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{containers?.map((container, index) => {
					const config = getConfig(container?.wasteType?.code)

					const containerName =
						getWasteTypeName(sanityWasteTypes || [], container.wasteType?.code) ||
						container.wasteType.name

					return (
						<tr key={index} className="border-b last-of-type:border-none h-12">
							<td>{container.wasteType?.code}</td>
							<td>
								<div className="mx-2">{containerName}</div>
							</td>
							{languages?.map(language => {
								const nameConfig = config?.names.find(n => n.locale === language?.code)
								return (
									<td key={language.code}>
										<input
											className="mx-2 px-2"
											id={`${container.id}-${language?.code}`}
											value={nameConfig?.displayName ?? ""}
											onChange={e =>
												onDisplayNameChange(
													container.wasteType?.code,
													e.target.value,
													language.code
												)
											}
										/>
									</td>
								)
							})}

							<td className="relative">
								<Popover>
									{({ close }) => (
										<>
											<Popover.Button>
												<div className="mx-4 min-w-[80px] mt-2 flex justify-center items-center">
													{config?.color ? (
														<Square color={config.color} />
													) : (
														<div className="underline cursor-pointer -mt-2">
															{t("actions:addColor")}
														</div>
													)}
												</div>
											</Popover.Button>
											<Popover.Panel className="absolute z-10 bg-grey1 p-4 border border-grey3 shadow bottom-0">
												<SketchPicker
													color={config?.color ?? undefined}
													onChange={color => onColorChange(container.wasteType?.code, color.hex)}
													styles={{
														default: {
															picker: {
																boxShadow: "none",
															},
														},
													}}
												/>
												<Button className="w-full mt-2" label={t("common:done")} onClick={close} />
											</Popover.Panel>
										</>
									)}
								</Popover>
							</td>
							<td>
								{(config?.color || !!config?.names?.filter(el => !!el.displayName).length) && (
									<div
										className="underline ml-4 cursor-pointer"
										onClick={() => {
											const { wasteTypeCode } = config

											const resetElement = data?.find(el => el.wasteTypeCode === wasteTypeCode)

											if (resetElement) {
												setUpdatedWasteTypes(prev => [
													...filter(prev, wt => wasteTypeCode !== wt.wasteTypeCode),
													{
														names: resetElement?.names.map(el => ({ ...el, displayName: "" })),
														wasteTypeCode,
														color: "",
													},
												])
												setHasUnsavedChanges(true)
											} else {
												setUpdatedWasteTypes(prev => [
													...filter(prev, wt => wasteTypeCode !== wt.wasteTypeCode),
													{
														names: [],
														wasteTypeCode,
														color: "",
													},
												])
											}
										}}
									>
										{t("actions:reset")}
									</div>
								)}
							</td>
						</tr>
					)
				})}
			</tbody>
		</table>
	)
}

const Square: React.FC<{ color: string }> = ({ color }) => {
	return (
		<div
			style={{
				width: 28,
				height: 28,
				backgroundColor: color,
			}}
		/>
	)
}
