import { Workbook } from "exceljs"
import { addColumns, downloadExcel, filterLinking } from "Utils/importCommon/downloadFunctions"
import { translate } from "translations/functions/hook"
import {
	getAccessTokenColumns,
	getCustomerColumns,
	getContactsColumns,
	getStationsColumns,
} from "./columnFunctions"

const addAccessTokensSheet = (
	workbook: Workbook,
	isMWM: boolean,
	includeLinking: boolean = true
) => {
	const sheet = workbook.addWorksheet(translate("importLabels:accessTokensSheetTitle"))

	const accessTokensColumns = getAccessTokenColumns(isMWM)
	const columns = includeLinking ? accessTokensColumns : filterLinking(accessTokensColumns)
	addColumns(sheet, columns)
}

const addCustomersSheet = (workbook: Workbook, isMWM: boolean) => {
	const sheet = workbook.addWorksheet(translate("importLabels:customersSheetTitle"))

	const customerColumns = getCustomerColumns({ isMWM }).filter(col => !col.isGenerated)
	addColumns(sheet, customerColumns)
}

const addContactsSheet = (workbook: Workbook) => {
	const sheet = workbook.addWorksheet(translate("importLabels:contactsSheetTitle"))

	const contactsColumns = getContactsColumns()
	addColumns(sheet, contactsColumns)
}

const addStationsSheet = (workbook: Workbook) => {
	const sheet = workbook.addWorksheet(translate("importLabels:accessParentsSheetTitle"))

	const stationsColumns = getStationsColumns()
	addColumns(sheet, stationsColumns)
}

export const downloadForAccessTokens = async (title: string, isMWM: boolean) => {
	const workbook = new Workbook()

	addAccessTokensSheet(workbook, isMWM, false)

	downloadExcel(title, workbook)
}

export const downloadForCustomers = async (title: string, isMWM: boolean) => {
	const workbook = new Workbook()

	addCustomersSheet(workbook, isMWM)
	if (isMWM) {
		addStationsSheet(workbook)
	} else {
		addContactsSheet(workbook)
	}
	addAccessTokensSheet(workbook, isMWM)

	downloadExcel(title, workbook)
}
