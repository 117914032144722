import { useTerminals } from "api/hooks/useTerminals"
import { FilterTimeFrameValue } from "components/pageCards/filterSort/filterCreators"
import { getTimeFrame } from "components/pageCards/filterSort/filterTimeFrame"
import { INumberEntryProps, NumberCard } from "components/pageCards/numberCard"
import { groupWasteTypes } from "pages/circularity/overview/components/WasteTypesInUse/functions"
import { useMemo, useState } from "react"
import { useCommonEntitiesStore } from "States/commonEntities"
import { useTrans } from "translations"
import { useDataWareHouse } from "Utils/api/datawarehouse/request"

export const InfrastructureMWM = () => {
	const [timeFrame] = useState(getTimeFrame(FilterTimeFrameValue.LAST_30_DAYS))
	const { t } = useTrans()
	const { terminals } = useTerminals()
	const { data: dwhData, isLoading } = useDataWareHouse({
		endpoint: "terminal-aggregates",
		filters: timeFrame,
	})
	const { wasteTypes: sanityWasteTypes } = useCommonEntitiesStore()

	const wasteTypesInUse = useMemo(
		() =>
			sanityWasteTypes && dwhData
				? groupWasteTypes(dwhData.terminalAggregates?.wasteTypes || [], sanityWasteTypes)
				: [],
		[dwhData, sanityWasteTypes]
	)

	const nrAccessParents = useMemo(
		() =>
			terminals.reduce((acc, terminal) => {
				acc += terminal.children?.length || 0
				return acc
			}, 0),
		[terminals]
	)

	const numbers: INumberEntryProps[] = [
		{ heading: t("entities:terminals"), data: terminals!.length | 0 },
		{ heading: t("entities:accessParents"), data: nrAccessParents },
		{ heading: t("statisticsLabels:wasteCategories"), data: wasteTypesInUse.length | 0 },
	]
	return (
		<NumberCard
			title="statisticsLabels:infrastructureSummary"
			numbers={numbers}
			isLoading={isLoading}
		/>
	)
}
