import { AlertLine } from "components/AlertLine/AlertLine"
import { Button } from "components/button/index"
import { Card, CardComponentVariant } from "components/pageCards/card"
import { FC, useEffect, useState } from "react"
import { useGlobalAlert } from "States/globalAlert"
import { useTerminalsState } from "States/Terminals"
import { useTrans } from "translations"
import { trpc } from "Utils/trpc"
import { CustomerWithDetailsAndCode } from "admin-client-server/src/router"

interface CustomerCommunicationCardProps {
	customer: CustomerWithDetailsAndCode
}

export const CustomerCommunicationCard: FC<CustomerCommunicationCardProps> = ({ customer }) => {
	const { t } = useTrans()
	const { setGlobalAlert } = useGlobalAlert()

	const { currentTerminal } = useTerminalsState()
	const [isEmailSent, setIsEmailSent] = useState(false)

	const {
		mutate: sendWelcomeEmail,
		data: result,
		isLoading,
		isSuccess,
		isError: isMutationError,
	} = trpc.sendWelcomeEmail.useMutation()

	useEffect(() => {
		setIsEmailSent(false)
	}, [customer])

	const onButtonClick = () => {
		sendWelcomeEmail({ customerId: customer.id, terminalId: currentTerminal?.id })
		setIsEmailSent(true)
	}

	const close = () => setIsEmailSent(false)

	const isError = isEmailSent && (isMutationError || (isSuccess && result?.status > 0))

	useEffect(() => {
		if (isEmailSent && !isLoading && !isError) {
			setGlobalAlert({
				type: "success",
				message: "hints:welcomeEmailSent",
			})
		}
	}, [isLoading, isError, setGlobalAlert, isEmailSent])

	return (
		<Card title={"drawerLabels:communication"} variant={CardComponentVariant.DRAWER}>
			<p className="mb-10 font-dmSans text-sm text-black">
				{t("hints:sendWelcomeEmailDescription")}
			</p>
			{isError ? (
				<div className="w-full font-bold text-carrotRed flex h-[40px]">
					<AlertLine type="error" message="errors:errorSendingEmail" close={close} isStatic />
				</div>
			) : (
				<>
					{!customer.companyCode && (
						<div className="w-full font-bold text-carrotRed mb-4">
							<AlertLine
								type="error"
								message="errors:companyCodeMissing"
								canClose={false}
								isStatic
							/>
						</div>
					)}
					<Button
						loading={isLoading}
						disabled={!customer.companyCode}
						label={"actions:sendWelcomeEmail"}
						color="secondary"
						className="float-right"
						onClick={onButtonClick}
					/>
				</>
			)}
		</Card>
	)
}
