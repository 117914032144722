import { Terminal } from "States/Terminals"
import { isValidEmail } from "Utils/isValidEmail"
import { isNumber } from "lodash"
import { isValidPhoneNumberAllowEmpty } from "Utils/isValidPhoneNumber"
import { isValidCoordinatesValue } from "Utils/isValidCoordinatesValue"
import { RealEstateCategory } from "api/types"

// returns a locize key to the error to be shown
type ValidationFunction = (value: any) => string | undefined

export const terminalNameValidation = ({
	value,
	terminals,
}: {
	value: string
	terminals?: Terminal[]
}) => {
	const duplicateName = terminals?.find(t => t.name === value)
	if (duplicateName) return "errors:duplicateValue"
}

export const buildingCategoryValidation = ({ value }: { value: any }) => {
	if (!Object.values(RealEstateCategory).includes(value)) {
		return "errors:invalidValue"
	}
}

export const contactEmailValidation = ({ value }: { value: string }) => {
	if (!isValidEmail(value)) {
		return "errors:wrongEmailFormat"
	}
}

export const phoneNumberValidation = ({ value }: { value: any }) => {
	if (!isValidPhoneNumberAllowEmpty(value?.toString())) {
		return "errors:wrongPhoneNumberFormat"
	}
}

export const numberValidation = ({ value }: { value: any }) => {
	if (value && !isNumber(Number(value))) {
		return "errors:invalidValue"
	}
}

export const linkedValueValidation = ({
	value,
	linkingValues = [],
}: {
	value: string
	linkingValues: string[]
}) => {
	if (value && !linkingValues.includes(value)) {
		return "errors:unrecognizedValue"
	}
}

type ValueInArrayProps = {
	value: string
	arrayOfValues: {
		id: string
	}[]
	errorKey?: string
}

export const valueInArrayValidation = ({
	value,
	arrayOfValues,
	errorKey = "invalidValue",
}: ValueInArrayProps) => {
	if (value && !arrayOfValues?.find(w => w.id === value)) {
		return `errors:${errorKey}`
	}
}

export const carrotAccessParentIDValidation = (props: ValueInArrayProps) =>
	valueInArrayValidation({ ...props, errorKey: "unrecognizedAccessParentID" })

export const uniquePropertyValidation = ({
	value,
	arrayOfValues,
	property,
}: {
	value: any
	arrayOfValues: { [k: string]: string }[]
	property: string
}) => {
	if (value && arrayOfValues?.find(el => el[property] === value.toString())) {
		return "importLabels:identityAlreadyExists"
	}
}

export const coordinatesValidation = ({ value }: { value: any }) => {
	if (value && !isValidCoordinatesValue(value.toString())) {
		return "errors:mustBeValidCoordinates"
	}
}

export const requiredFieldValidation = ({ value }: { value: string }) => {
	if (!value) return "errors:requiredFields"
}

export const anythingAllowed = () => undefined

export const validate = (validationFunctions: ValidationFunction[], funcProps: any) => {
	const results = validationFunctions.map(func => func(funcProps))

	const errorResults = results.filter(Boolean)

	return errorResults[0]
}
